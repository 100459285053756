'use client';

import { motion } from 'motion/react';
import Image from 'next/image';

import ClientCTAButton from '@/app/components/ui/Buttons/ClientCTAButton';
import { cn } from '@/app/lib/utils/cn';
import { paddingClasses } from '@/app/lib/utils/getPaddingClasses';

export default function MarqueeComponent({ content }: any) {
  const {
    marqueeComponent: { label, title, description, ctaButton, integrationLogosCollection, spacingTop, spacingBottom },
  } = content;

  const paddingTop = paddingClasses.top?.[spacingTop as keyof typeof paddingClasses.top] || 'pt-4';
  const paddingBottom = paddingClasses.bottom?.[spacingBottom as keyof typeof paddingClasses.bottom] || 'pb-4';

  //   const splitLogos1 = integrationLogosCollection.items.reduce((acc: any, logo: any, index: number) => {
  //     const arrayIndex = Math.floor(index / (integrationLogosCollection.items.length / 2));
  //     if (!acc[arrayIndex]) {
  //         acc[arrayIndex] = []
  //     }
  //     acc[arrayIndex].push(logo)
  //     return acc
  //   }, [])

  const halfLength: number = Math.floor(integrationLogosCollection.items.length / 2);

  const splitLogos2: any[] = [integrationLogosCollection.items.slice(0, halfLength), integrationLogosCollection.items.slice(halfLength)];

  return (
    <section className={cn('grid h-full w-full grid-cols-12 overflow-x-clip', paddingTop, paddingBottom)}>
      <div className='col-span-full bg-SLATE_BLUE py-20 flex flex-col items-center'>
        <article className="z-10 grid grid-cols-12 text-WHITE sm:px-4">
          <div className="z-10 col-span-full justify-self-center">
            {label && <h2 className="mb-1 text-Pill text-GREEN-500 sm:mb-4">{label}</h2>}
            {title && <h2 className="text-H1 lg:text-H2 md:text-H1-Mobile sm:mb-4 max-w-[20ch] text-center">{title}</h2>}
            {description && (
              <p className="mt-6 w-full max-w-[55ch] text-balance text-Body-Large md:text-Body sm:max-w-[40ch]">{description}</p>
            )}
          </div>
        </article>
        <article className="col-span-full row-start-2 text-white">
          {splitLogos2.length > 0 && <DoubleScrollingLogos logos={splitLogos2} />}
        </article>
        {ctaButton && <ClientCTAButton buttonData={ctaButton} className="z-10 col-span-full mt-15 sm:text-xs" />}
      </div>
    </section>
  );
}

const DoubleScrollingLogos = ({ logos }: any) => {
  const [logosTop, logosBottom] = logos;
  return (
    <section className="w-full pt-20">
      {logosTop.length > 0 && (
        <div className="flex w-full">
          <TranslateWrapper>
            <LogoItemsTop logos={logosTop} />
          </TranslateWrapper>
          <TranslateWrapper>
            <LogoItemsTop logos={logosTop} />
          </TranslateWrapper>
          <TranslateWrapper>
            <LogoItemsTop logos={logosTop} />
          </TranslateWrapper>
        </div>
      )}
      {logosBottom.length > 0 && (
        <div className="mt-8 flex w-full">
          <TranslateWrapper reverse>
            <LogoItemsBottom logos={logosBottom} />
          </TranslateWrapper>
          <TranslateWrapper reverse>
            <LogoItemsBottom logos={logosBottom} />
          </TranslateWrapper>
          <TranslateWrapper reverse>
            <LogoItemsBottom logos={logosBottom} />
          </TranslateWrapper>
        </div>
      )}
    </section>
  );
};

const TranslateWrapper = ({ children, reverse }: any) => {
  return (
    <motion.div
      initial={{ translateX: reverse ? '-100%' : '0%' }}
      animate={{ translateX: reverse ? '0%' : '-100%' }}
      transition={{ duration: 120, repeat: Infinity, ease: 'linear' }}
      className="flex flex-shrink-0 items-center gap-10 md:gap-5"
    >
      {children}
    </motion.div>
  );
};

const LogoItem = ({ logoData }: any) => {
  return (
    <div className="user-select-none flex-shrink-0">
      <Image
        src={logoData.url}
        alt={logoData.title}
        width={logoData.width}
        height={logoData.height}
        className="user-select-none h-[90px] w-[260px] object-contain md:h-[60px] md:w-[180px]"
        title={logoData.title}
      />
    </div>
  );
};

const LogoItemsTop = ({ logos }: any) => (
  <>
    {logos.map((logo: any, index: number) => (
      <LogoItem key={`${logo.title}-${index}`} logoData={logo} />
    ))}
  </>
);

const LogoItemsBottom = ({ logos }: any) => (
  <>
    {logos.map((logo: any, index: number) => (
      <LogoItem key={`${logo.title}-${index}`} logoData={logo} />
    ))}
  </>
);
