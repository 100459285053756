import { clsx } from 'clsx';
import { motion, Variants } from 'motion/react';
import { Link } from 'next-view-transitions';

import { borderColors, cardLabels,fadeInUp, labelColors } from '@/app/components/ui/NewsAndPress/sharedData';
import formatDate from '@/app/lib/utils/formatDate';
import { getHref } from '@/app/lib/utils/getHref';
import { ContentfulListingTileProps } from '@/app/types/contentful/types';

export function ListingTile({ card }: { card: ContentfulListingTileProps }) {
  const { label, title, date, route, slug } = card;
  const formattedDate = formatDate(date, 'short');
  const href: string = getHref(route, slug, null);

  // Adjust the label for display
  const displayLabel = label === 'Press' ? 'Press Release' : label;

  return (
    <motion.div
      variants={fadeInUp as Variants}
      className={clsx(
        'col-span-6 min-h-[180px] cursor-pointer rounded-md border-[1px] border-WHITE/20 p-4 transition-colors duration-300 ease-linear md:col-span-full sm:hover:border-WHITE/20',
        borderColors[label as keyof typeof borderColors],
      )}
    >
      <Link href={href} className="grid h-full grid-cols-6 gap-8">
        <div className="col-span-5 flex flex-col justify-between">
          <div>
            {displayLabel && (
              <p className={clsx('font-bold text-Pill', labelColors[label as keyof typeof labelColors])}>
                {cardLabels[displayLabel as keyof typeof cardLabels]}
              </p>
            )}
            {title && <p className="sm:text-bold mt-2 line-clamp-3 text-H2-Mobile text-WHITE sm:text-H4">{title}</p>}
          </div>
          {formattedDate && <p className="mt-2 text-Body text-WHITE/60 sm:text-Body">{formattedDate}</p>}
        </div>
        <div className="col-start-6 row-start-1 self-start justify-self-end rounded-md border-none bg-WHITE/20 px-10px py-10px text-WHITE hover:bg-opacity-60 sm:px-[6px] sm:py-[6px]">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 12 12" fill="none">
            <path
              d="M1 11L10.9999 1M10.9999 1V10.6M10.9999 1H1.4"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </Link>
    </motion.div>
  );
}
