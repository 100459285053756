export const labelColors = {
  null: 'text-WHITE/60',
  News: 'text-GREEN-500',
  Press: 'text-PURP-200',
  'Press Release': 'text-PURP-200',
  Report: 'text-BLUE-400',
  Article: 'text-PURP',
  Interview: 'text-GREEN',
  Podcast: 'text-BLUE',
} as const;

export const borderColors = {
  null: 'hover:border-WHITE/20 active:border-WHITE/20',
  News: 'hover:border-GREEN-500 active:border-GREEN-500',
  Press: 'hover:border-PURP-200 active:border-PURP-200',
  'Press Release': 'hover:border-PURP-200 active:border-PURP-200',
  Report: 'hover:border-BLUE-400 active:border-BLUE-400',
  Article: 'hover:border-PURP active:border-PURP',
  Interview: 'hover:border-GREEN active:border-GREEN',
  Podcast: 'hover:border-BLUE active:border-BLUE',
} as const;

export const cardLabels = {
  News: 'News',
  Press: 'Press Release',
  Report: 'Report',
  Article: 'Article',
  Interview: 'Interview',
  Podcast: 'Podcast',
  'Press Release': 'Press Release',
} as const;

export const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
  transition: { duration: 0.5 },
} as const;
