'use client';

import { useEffect,useRef, useState } from "react";

export default function useImageLoad() {
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imageRef.current) {
      if (imageRef.current?.complete) {
        setImageLoaded(true);
      } else {
        const img = imageRef.current;
        img.onload = () => setImageLoaded(true);
      }
      const img = imageRef.current;
      return () => {
        if (img) {
          img.onload = null;
        }
      };
    }
  }, []);

  return { imageLoaded, imageRef };
}
