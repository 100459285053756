function formatDate(date: string | Date, format: 'short' | 'long' = 'short') {
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    month: `${format === 'short' ? 'short' : 'long'}`,
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
  });

  return formattedDate as string;
}

export default formatDate;
