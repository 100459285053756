'use client';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES, MARKS } from '@contentful/rich-text-types';
import clsx from 'clsx';
import { ExpandIcon, XIcon } from 'lucide-react';
import { AnimatePresence, motion } from 'motion/react';
import Image from 'next/image';
import { Link } from 'next-view-transitions';
import { ReactNode, useState } from 'react';

import LinkButton from '@/app/components/ui/Buttons/LinkButton';
import LinkComponent from '@/app/components/ui/Interactive/Link';
import VideoPlayer from '@/app/components/ui/Video/VideoPlayer';
import { getHref } from '@/app/lib/utils/getHref';
import { paddingClasses } from '@/app/lib/utils/getPaddingClasses';

import ClientCTAButton from '../Buttons/ClientCTAButton';

const backgroundColorClasses: { [key: string]: string | string[] | null } = {
  None: 'bg-tansparent',
  'Slate Blue': 'bg-SLATE_BLUE',
  'Off Black': 'bg-OFF-BLACK',
} as const;

export default function ListingTextComponent({ content, locationData }: any) {
  const {
    listingTextComponent: { text, backgroundColor, spacingTop, spacingBottom },
  } = content;

  const [fullscreenImage, setFullscreenImage] = useState<{ url: string; alt: string; width: number; height: number } | null>(null);

  const paddingTop = paddingClasses.top?.[spacingTop as keyof typeof paddingClasses.top] || 'pt-4';
  const paddingBottom = paddingClasses.bottom?.[spacingBottom as keyof typeof paddingClasses.bottom] || 'pb-4';

  const handleImageClick = (url: string, alt: string, width: number, height: number) => {
    setFullscreenImage({ url, alt, width, height });
  };

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (_node: any, children: any) => (
        <h1 className="col-start-3 col-end-11 text-balance pb-4 pt-6 text-RichText-H1 text-WHITE/90 first:col-span-full first:pt-0 first:text-center lg:col-span-full md:text-RichText-H2 md-lg:col-span-full">
          {children}
        </h1>
      ),
      [BLOCKS.HEADING_2]: (_node: any, children: any) => (
        <h2 className="col-start-3 col-end-11 text-balance pb-3 pt-6 text-RichText-H2 text-WHITE/90 first:pt-0 md:text-RichText-H3 md-lg:col-span-full">
          {children}
        </h2>
      ),
      [BLOCKS.HEADING_3]: (_node: any, children: any) => (
        <h3 className="col-start-3 col-end-11 text-balance pb-3 pt-6 text-RichText-H3 font-bold text-WHITE/90 first:pt-0 md:text-RichText-H4 md-lg:col-span-full">
          {children}
        </h3>
      ),
      [BLOCKS.HEADING_4]: (_node: any, children: any) => (
        <h4 className="col-start-3 col-end-11 text-balance pb-3 pt-6 text-RichText-H4 font-bold text-WHITE/90 first:pt-0 md:text-RichText-H5 md-lg:col-span-full">
          {children}
        </h4>
      ),
      [BLOCKS.HEADING_5]: (_node: any, children: any) => (
        <h5 className="col-start-3 col-end-11 text-balance pb-3 pt-6 text-RichText-H5 font-bold leading-tight text-WHITE/90 first:pt-0 md:text-RichText-H6 md-lg:col-span-full">
          {children}
        </h5>
      ),
      [BLOCKS.HEADING_6]: (_node: any, children: any) => (
        <h6 className="col-start-3 col-end-11 text-balance pb-3 pt-6 text-RichText-H6 font-bold first:pt-0 md:text-Body sm:col-span-full md-lg:col-span-full">
          {children}
        </h6>
      ),
      [BLOCKS.PARAGRAPH]: (_node: any, children: any) => (
        <p
          className={clsx(
            'col-start-3 col-end-11 w-full space-y-5 text-pretty pb-4 text-RichText-Body font-normal text-WHITE/90 last:pb-0 md-lg:col-span-full',
          )}
        >
          {children}
        </p>
      ),
      [BLOCKS.QUOTE]: (_node: any, children: any) => (
        <blockquote className="col-start-3 col-end-11 text-balance rounded-md bg-slate-700 px-6 py-6 text-Body-Large font-normal text-WHITE md:text-RichText-Body md-lg:col-span-full">
          {children}
        </blockquote>
      ),
      [BLOCKS.HR]: (_node: any) => <hr className="border-t-2 border-WHITE/50 py-4" />,
      [BLOCKS.UL_LIST]: (_node: any, children: any) => (
        <ul className="col-start-3 col-end-11 h-full w-full list-disc justify-self-center pb-2 pl-8 pt-2 md-lg:col-span-full">{children}</ul>
      ),
      [BLOCKS.OL_LIST]: (_node: any, children: any) => (
        <ol className="col-start-3 col-end-11 h-full w-full list-decimal justify-self-center pb-2 pl-8 pt-2 md-lg:col-span-full">
          {children}
        </ol>
      ),
      [BLOCKS.LIST_ITEM]: (_node: any, children: any) => (
        <li className="w-full text-balance pb-3 pt-3 text-RichText-Body font-normal text-WHITE/90 marker:text-RichText-Body md:text-Body-Large marker:md:text-Body-Large sm:text-Body marker:sm:text-[0.9rem] min-lg:max-w-[75ch]">
          {children}
        </li>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const assetId = node.data.target.sys.id;
        const asset = text.links.assets.block.find((asset: any) => asset.sys.id === assetId);
        const isFirstAsset = text.links.assets.block.indexOf(asset) === 0;

        if (!asset) return null;

        if (asset.url.includes('.mp4')) {
          const aspectRatio = asset.height / asset.width;
          return (
            <article className="col-span-full h-full w-full py-12 md:mt-15">
              <VideoPlayer url={asset.url} aspectRatio={aspectRatio} />
            </article>
          );
        } else if (
          asset.url.includes('.jpg') ||
          asset.url.includes('.jpeg') ||
          asset.url.includes('.png') ||
          asset.url.includes('.gif') ||
          asset.url.includes('.svg') ||
          asset.url.includes('.webp')
        ) {
          // When image is clicked, it opens in a modal by saving the image data to the fullscreenImage state and passing it to the modal
          return isFirstAsset ? (
            <Image
              width={asset.width || 350}
              height={asset.height || 350}
              src={asset.url}
              alt={asset.description || 'Embedded asset'}
              className="col-start-3 col-end-11 mb-5 h-auto w-full cursor-zoom-in rounded-md md-lg:col-span-full"
            />
          ) : (
            <figure className="group relative col-start-3 col-end-11 mx-auto my-5 cursor-pointer md-lg:col-span-full">
              <div
                onClick={() => handleImageClick(asset.url, asset.description || 'Embedded asset', asset.width || 800, asset.height || 600)}
                onKeyDown={e => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    handleImageClick(asset.url, asset.description || 'Embedded asset', asset.width || 800, asset.height || 600);
                  }
                }}
                role="button"
                tabIndex={0}
                aria-label={`View ${asset.description || 'image'} in full screen`}
                className="relative"
              >
                <Image
                  width={asset.width || 350}
                  height={asset.height || 350}
                  title="Click to view full size"
                  src={asset.url}
                  alt={asset.description || 'Embedded asset'}
                  className="h-auto w-full cursor-zoom-in rounded-md transition-transform duration-300 group-hover:scale-[1.01]"
                />
              </div>
              <ExpandIcon
                onClick={() => handleImageClick(asset.url, asset.description || 'Embedded asset', asset.width || 800, asset.height || 600)}
                className="absolute right-2 top-2 h-8 w-8 rounded-full border border-WHITE/40 bg-BLACK/50 p-1 text-WHITE transition-all duration-300 hover:scale-110 hover:text-GREEN-500 focus:ring-2 focus:ring-inset focus:ring-GREEN-500"
              />
              {/* {asset.description && <figcaption className="mt-2 text-Pill text-WHITE/60">{asset.description}</figcaption>} */}
            </figure>
          );
        }

        return null;
      },
      [INLINES.HYPERLINK]: (node: any, children: any) => {
        const href = node.data.uri;
        return (
          <Link
            href={href}
            target="_blank"
            className="text-BLUE-400 underline transition-all duration-300 hover:text-BLUE-400/80 focus:text-BLUE-400/80 active:text-BLUE-400/60"
          >
            {children}
          </Link>
        );
      },
      [INLINES.EMBEDDED_ENTRY]: (node: any) => {
        const entryId = node.data.target.sys.id;
        const entry = text.links.entries.inline.find((entry: any) => entry.sys.id === entryId);

        if (!entry) return null;

        if (entry.__typename === 'ButtonComponent') {
          const findButtons = text.links.entries.inline.filter((entry: any) => entry.__typename === 'ButtonComponent');
          const isFirstButton = findButtons.indexOf(entry) === 0;
          return (
            <LinkButton
              key={entry.sys.id}
              hrefData={entry}
              label={entry.label}
              buttonType={entry.buttonType}
              className={clsx('mt-4 inline-block', {
                'ml-5': text.links.entries.inline.length > 1 && !isFirstButton,
                'ml-0': isFirstButton,
              })}
            />
          );
        }

        if (entry.__typename === 'FormButton') {
          return <ClientCTAButton buttonData={entry} className="mt-4 inline-block" />;
        }

        if (entry.__typename === 'DetailPage') {
          return (
            <LinkComponent
              key={entry.sys.id}
              href={getHref(entry?.route, entry?.slug, entry?.url)}
              className="ml-1 inline border-none text-BLUE-400 underline"
            >
              Go To Page: {entry.title} {'->'}
            </LinkComponent>
          );
        }
      },
    },
    renderMark: {
      [MARKS.BOLD]: (text: ReactNode) => (
        <strong className={clsx('font-bold', locationData === 'blog' ? 'text-GREEN-500' : '')}>{text}</strong>
      ),
      [MARKS.ITALIC]: (text: ReactNode) => <em className="italic">{text}</em>,
      [MARKS.UNDERLINE]: (text: ReactNode) => <span className="underline">{text}</span>,
      [MARKS.SUPERSCRIPT]: (text: ReactNode) => <sup className="text-xs">{text}</sup>,
      [MARKS.SUBSCRIPT]: (text: ReactNode) => <sub className="text-xs">{text}</sub>,
      [MARKS.STRIKETHROUGH]: (text: ReactNode) => <span className="line-through">{text}</span>,
    },
  };

  return (
    <>
      <section className={`${backgroundColorClasses[backgroundColor]} ${paddingTop} ${paddingBottom}`}>
        <div className="container flex h-full w-full flex-col items-center">
          <article className="grid grid-cols-12">{documentToReactComponents(text?.json as Document, options)}</article>
        </div>
      </section>
      {/* Fullscreen Image Modal */}
      <AnimatePresence mode="sync">
        {fullscreenImage && (
          <motion.div
            initial={{ opacity: 0, scale: 0.95, transition: { duration: 0.3 } }}
            animate={{ opacity: 1, scale: 1, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, transition: { duration: 0.3, delay: 0.3 } }}
            className="fixed inset-0 z-50 bg-black/80 p-4 backdrop-blur-sm"
            role="dialog"
            aria-modal="true"
            aria-label="Image fullscreen view"
          >
            <div className="flex h-full items-center justify-center">
              <div className="max-h-[90vh] max-w-[90vw]">
                {fullscreenImage && (
                  <motion.button
                    initial={{ opacity: 0, x: 100, y: -100 }}
                    animate={{ opacity: 1, x: 0, y: 0, transition: { duration: 0.5 } }}
                    exit={{ opacity: 0, x: 100, y: -100, transition: { duration: 0.3 } }}
                    className="absolute right-4 top-4 z-[60] text-white hover:text-gray-300 focus:outline-none"
                    onClick={() => setFullscreenImage(null)}
                    aria-label="Close fullscreen view"
                    title="Close fullscreen view"
                  >
                    <XIcon className="active:text-PURPLE-500 h-8 w-8 text-GREEN-500 transition-all duration-300 hover:scale-110 focus:ring-2 focus:ring-inset focus:ring-GREEN-500" />
                  </motion.button>
                )}

                {fullscreenImage && (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.95, filter: 'blur(20px)', transition: { duration: 0.3, delay: 0.3 } }}
                    animate={{ opacity: 1, scale: 1, filter: 'blur(0px)', transition: { duration: 0.3, delay: 0.3 } }}
                    exit={{ opacity: 0, scale: 0.95, filter: 'blur(20px)', transition: { duration: 0.3 } }}
                    className="user-select-none h-full w-full"
                  >
                    <Image
                      src={fullscreenImage.url}
                      alt={fullscreenImage.alt}
                      width={fullscreenImage.width}
                      height={fullscreenImage.height}
                      className="max-h-[85vh] max-w-[85vw] object-contain"
                    />
                  </motion.div>
                )}
                <div
                  className="absolute inset-0 cursor-zoom-out"
                  onClick={() => setFullscreenImage(null)}
                  role="button"
                  title="Close fullscreen view"
                  tabIndex={0}
                  onKeyDown={e => {
                    if (e.key === 'Escape' || e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      setFullscreenImage(null);
                    }
                  }}
                  aria-label="Close fullscreen view"
                />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
