'use client';

import { motion } from 'motion/react';
import Image from 'next/image';
import Link from 'next/link';

import formatDate from '@/app/lib/utils/formatDate';
import { getHref } from '@/app/lib/utils/getHref';

import type { ListingCardProps } from './ListingComponent';

export default function ListingCard({ card }: { card: ListingCardProps }) {
  const { title, featuredImage, date, url } = card;
  const formattedDate: string = formatDate(date, 'short');
  const href: string = getHref(null, null, url);
  
  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className="col-span-4 md:col-span-6 sm:col-span-full"
    >
      <Link href={href || '#'} target="_blank" className="group flex flex-col gap-y-4 text-WHITE">
        {featuredImage ? (
          <Image
            src={featuredImage.url}
            alt={featuredImage.description || featuredImage.title || ''}
            width={featuredImage.width || 500}
            height={featuredImage.height || 500}
            sizes="(max-width: 768px) 100vw, 768px"
            draggable={false}
            className="aspect-video rounded-md border border-WHITE/20 object-cover"
          />
        ) : (
          <Image
            src="/assets/placeholder.svg"
            alt="placeholder"
            width={500}
            height={500}
            sizes="(max-width: 768px) 100vw, 768px"
            draggable={false}
            className="aspect-video h-full w-full rounded-md bg-green-900 object-cover"
          />
        )}
        <h2 className="relative line-clamp-3 text-H3">
          <span className="inline bg-gradient-to-r from-WHITE to-WHITE bg-[size:0%_0px] bg-[position:0_100%] bg-no-repeat leading-tight transition-all duration-500 ease-underline-bezier group-hover:bg-[size:100%_1px]">
            {title}
          </span>
        </h2>
        <p className="mt-2">{formattedDate}</p>
      </Link>
    </motion.div>
  );
}