'use client';

import clsx from 'clsx';
import { AnimatePresence, motion } from 'motion/react';
import { useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import LoadingSpinner from '@/app/components/ui/Loader/LoadingSpinner';
import ThreatListingCard from '@/app/components/ui/ThreatBriefings/ThreatListingCard';
import type { ClientThreatListingProps, ThreatListingCardProps } from '@/app/types/contentful/types';

// Animation variants for staggered children animation
const stagger = {
  animate: {
    transition: {
      staggerChildren: 0.03, // Slight delay between each child animation
    },
  },
} as const;

export default function ClientThreatListing({
  numberOfCards,
  threatBriefings,
  totalThreatBriefings,
  title,
  category,
}: ClientThreatListingProps) {
  const [briefings, setBriefings] = useState(threatBriefings);
  const [isLoading, setIsLoading] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  const loadMore = useCallback(async () => {
    if (isLoading || briefings.length >= totalThreatBriefings) return;

    try {
      setIsLoading(true);
      const skip = briefings.length;
      const response = await fetch(`/api/threat-briefings?skip=${skip}&limit=12&category=${category}`);
      const data = await response.json();

      setBriefings(prev => [...prev, ...data.items]);
    } catch (error) {
      console.error('Error loading more threat briefings:', error);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, briefings.length, totalThreatBriefings, category]);

  useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [inView, loadMore]);

  return (
    <>
      <motion.article
        layout="position"
        variants={stagger}
        initial="initial"
        animate="animate"
        className={clsx(
          'col-span-full grid grid-cols-12 gap-x-8 gap-y-20 md:row-start-2 sm:gap-x-0 sm:gap-y-15',
          title ? 'row-start-2' : 'row-start-1',
        )}
      >
        <AnimatePresence>
          {briefings.map((card: ThreatListingCardProps, index: number) => (
            <ThreatListingCard key={`${card._id}-${index}`} card={card} initiallyVisible={index < numberOfCards} />
          ))}
        </AnimatePresence>
      </motion.article>

      {briefings.length < totalThreatBriefings && (
        <div ref={ref} className="relative col-span-full row-start-3 h-20">
          <LoadingSpinner className="mx-auto h-10 w-10" />
        </div>
      )}
    </>
  );
}
