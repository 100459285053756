'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import RSABookADemoFormInputs from '@/app/components/ui/Forms/RSA25_BookADemoForm/RSABookADemoFormInputs';
import { getHubSpotCookie } from '@/app/lib/utils/getHubSpotCookie';

// Form Schema: Defines the structure and validation rules for the form data
const formSchema = z.object({
  firstname: z.string().min(2, 'First name is required'),
  lastname: z.string().min(2, 'Last name is required'),
  region__c: z.string().min(1, 'Please select a region'),
  email: z.string().email('Invalid email address'),
  phone: z.string().regex(/^\+?[1-9]\d{1,14}$/, 'Invalid phone number'),
  company: z.string().min(1, 'Company name is required'),
  jobtitle: z.string().min(1, 'Job title is required'),
  how_did_you_hear_about_us_: z.string().min(1, 'Please tell us how you heard about us'),
  // Add optional UTM fields
  utm_source: z.string().optional(),
  utm_medium: z.string().optional(),
  utm_campaign: z.string().optional(),
  utm_content: z.string().optional(),
  utm_term: z.string().optional(),
});

// Form Data Type: Inferred from the schema for type safety
type FormData = z.infer<typeof formSchema>;

export default function RSABookADemoForm({ handleCloseOnSubmit }: { handleCloseOnSubmit?: () => void }): JSX.Element {
  const [submittedMessage, setSubmittedMessage] = useState<string | null>(null);
  const [serverError, setServerError] = useState<string | null>(null);
  const [formContent, setFormContent] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Initialize react-hook-form with zod resolver for validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
  });

  // Fetch form content from API on component mount
  useEffect(() => {
    fetch(`/api/getForm?formType=RSA 2025 Book a Demo`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(content => setFormContent(content))
      .catch(error => console.error('Error fetching form content:', error));
  }, []);

  // Extract UTM parameters from URL on component mount
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const utmParams = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_content',
        'utm_term'
      ];
      
      utmParams.forEach(param => {
        const value = urlParams.get(param);
        if (value) {
          setValue(param as keyof FormData, value);
        }
      });
    }
  }, [setValue]);

  // Form submission handler
  const onSubmit: SubmitHandler<FormData> = async data => {
    try {
      setIsSubmitting(true);

      // Prepare the submission payload
      const payload: any = {
        fields: Object.entries(data).map(([name, value]) => ({ name, value })),
      };

      // Get IP address and hubspot cookie
      const [ipResponse, hutk] = await Promise.all([
        fetch('https://api.ipify.org?format=json').then(r => r.json()),
        getHubSpotCookie() // This now loads the script temporarily, gets the cookie, and removes the script
      ]);

      payload.context = {
        pageUri: 'https://www.cyware.com/rsa-2025',
        pageName: 'RSA 2025 Book a Demo',
        hutk, // Add the hubspot cookie if available
        ipAddress: ipResponse.ip,
      };

      // Send form data to HubSpot Forms API
      const response = await fetch(formContent?.submitUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Form submission failed');
      }

      setSubmittedMessage('Thank you! Our team will contact you to schedule a demo.');

      // Reset form and close modal after successful submission
      setTimeout(() => {
        reset();
        handleCloseOnSubmit && handleCloseOnSubmit();
        setSubmittedMessage(null);
      }, 1500);

      setIsSubmitting(false);
    } catch (error) {
      console.error('Form submission error:', error);
      setServerError('An error occurred. Please try again.');
      setIsSubmitting(false);
    }
  };

  // Error handler for form validation errors
  const onError: SubmitErrorHandler<FormData> = errors => {
    console.error('Form validation errors:', errors);
  };

  return (
    <>
      <RSABookADemoFormInputs
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        onError={onError}
        errors={errors}
        register={register}
        isSubmitting={isSubmitting}
        submittedMessage={submittedMessage}
        serverError={serverError}
      />
    </>
  );
}

// ? This form submits via HubSpot Forms API and Hubspot Tracking Cookie (without the tracking script)