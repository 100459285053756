"use client"

import clsx from "clsx"
import { NavArrowDown } from 'iconoir-react'
import * as React from "react"

interface AccordionItemProps {
  value: string
  trigger: React.ReactNode
  children: React.ReactNode
  className?: string
  isOpen: boolean
  onToggle: (value: string) => void
}

const AccordionItem = ({ 
  value, 
  trigger, 
  children, 
  className,
  isOpen,
  onToggle 
}: AccordionItemProps) => {
  return (
    <div 
      role="presentation"
      className={clsx("border-b border-WHITE/40", className)}
    >
      <section
        aria-labelledby={`accordion-header-${value}`}
      >
        <h3 className="m-0">
          <button
            id={`accordion-header-${value}`}
            onClick={() => onToggle(value)}
            className={clsx(
              "flex w-full items-center justify-between py-4 font-medium",
              "transition-all hover:underline focus-visible:outline-2",
              "focus-visible:outline-offset-2 focus-visible:outline-GREEN-500"
            )}
            aria-expanded={isOpen}
            aria-controls={`accordion-content-${value}`}
          >
            {trigger}
            <NavArrowDown 
              aria-hidden="true"
              className={clsx(
                "h-4 w-4 shrink-0 transition-transform duration-200",
                isOpen && "rotate-180",
                "motion-safe:transition-transform motion-reduce:transition-none"
              )} 
            />
          </button>
        </h3>
        <div
          id={`accordion-content-${value}`}
          role="region"
          aria-labelledby={`accordion-header-${value}`}
          hidden={!isOpen}
          className={clsx(
            "grid",
            "motion-safe:transition-all motion-reduce:transition-none",
            "duration-200 ease-out",
            isOpen ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
          )}
        >
          <div className="overflow-hidden">
            <div className={clsx(
              "pb-4 pt-0 text-sm whitespace-pre-wrap",
              "motion-safe:transition-all motion-reduce:transition-none duration-200",
              isOpen ? "opacity-100" : "opacity-0"
            )}>
              {children}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export { AccordionItem }