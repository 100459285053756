'use client';

import clsx from 'clsx';
import { motion } from 'motion/react';
import Image from 'next/image';

import useImageLoad from '@/app/lib/hooks/useImageLoad';
import type { ContenfulProductCarouselItem } from '@/app/types/contentful/types';

import LinkButton from '../Buttons/LinkButton';
import LoadingSpinner from '../Loader/LoadingSpinner';

export default function ProductCarouselItemDesktop({ item, bgColor }: { item: ContenfulProductCarouselItem; bgColor: string }) {

  const { imageLoaded, imageRef } = useImageLoad();
  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -20 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      className="flex h-full w-full items-center justify-between gap-2 py-20 pr-10 md:flex-col md:py-8 md:px-0 md:pr-0"
      style={{ backgroundColor: bgColor }}
    >
      <div className="relative w-full flex-1 pl-7 md:pl-0 flex items-center justify-center">
        {!imageLoaded && <LoadingSpinner />}
        {item.featuredImage?.url && (
          <Image
            ref={imageRef}
            src={item.featuredImage.url}
            alt={item.featuredImage.description || item.featuredImage.title}
            width={item.featuredImage.width}
            height={item.featuredImage.height}
            sizes="(max-width: 768px) 100vw, 768px"
            draggable={false}
            className={clsx('object-contain transition-opacity duration-150 ease-in-out md:max-w-[65%]', imageLoaded ? 'opacity-100' : 'opacity-0')}
          />
        )}
      </div>
      <div className="mb-5 flex flex-1 flex-col text-WHITE px-10 w-full md:flex-[0_0_auto]">
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.3 }}
          className="max-w-[50ch] text-H2 font-bold"
        >
          {item.itemTitleDesktop}
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.3 }}
          className="max-w-[60ch] pt-6 mb-8"
        >
          {item.itemDescription}
        </motion.p>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.3 }}
        >
          <LinkButton
            hrefData={item}
            label={item.linkLabel}
            buttonType="Primary"
          />
        </motion.div>
      </div>
    </motion.div>
  );
}
