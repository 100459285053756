'use client';

import clsx from 'clsx';
import { AnimatePresence, motion } from 'motion/react';
import Image from 'next/image';
import { useParams } from 'next/navigation';
import { Link } from 'next-view-transitions';
import { useRef, useState } from 'react';

import useImageLoad from '@/app/lib/hooks/useImageLoad';
import { getHref } from '@/app/lib/utils/getHref';
import { paddingClasses } from '@/app/lib/utils/getPaddingClasses';
import type { ContentfulImage, HeadingComponentData } from '@/app/types/contentful/types';

import Heading from './Heading/Heading';
import Button from './Interactive/Button';
import LoadingSpinner from './Loader/LoadingSpinner';
import { SvgLinesComponent } from './SvgLinesComponent';

interface HeadingListingComponentProps {
  content: {
    headingListingComponent: {
      heading: HeadingComponentData;
      cardsCollection: {
        items: ListingCardProps[];
      };
      numberOfCards: number;
      showGridLines: boolean;
      backgroundType: string;
      spacingTop: number;
      spacingBottom: number;
    };
  };
}

interface ListingCardProps {
  _id: string;
  title: string;
  date: string;
  route: string;
  slug: string;
  url: string;
  featuredImage: ContentfulImage;
}

const backgroundTypeClasses: { [key: string]: string | string[] | null } = {
  None: 'bg-BLACK',
  'Slate Blue': 'bg-SLATE_BLUE',
  'Off Black': 'bg-OFF-BLACK',
  'Purple Gradient Top': 'bg-gradient-top-purple bg-top',
  'Blue Gradient Top': 'bg-gradient-top-blue bg-top',
  'Purple Gradient Center': 'bg-gradient-center-purple bg-center',
  'Blue Gradient Center': 'bg-gradient-center-blue bg-center',
  'Purple Gradient Bottom': 'bg-gradient-bottom-purple bg-bottom',
  'Blue Gradient Bottom': 'bg-gradient-bottom-blue bg-bottom',
  'Purple Gradient Top/Bottom': ['bg-gradient-top-purple bg-top', 'bg-gradient-bottom-purple bg-bottom'],
  'Blue Gradient Top/Bottom': ['bg-gradient-top-blue bg-top', 'bg-gradient-bottom-blue bg-bottom'],
} as const;

export default function HeadingListingComponent({ content }: HeadingListingComponentProps) {
  const {
    headingListingComponent: { heading, cardsCollection, numberOfCards, showGridLines, backgroundType, spacingTop, spacingBottom },
  } = content;
  const [showAll, setShowAll] = useState<boolean>(false);
  const sectionRef = useRef<HTMLElement>(null);
  const numberOfCardsToShow = numberOfCards || 0;
  const params = useParams();

  const paddingTop = paddingClasses.top?.[spacingTop as keyof typeof paddingClasses.top] || 'pt-4';
  const paddingBottom = paddingClasses.bottom?.[spacingBottom as keyof typeof paddingClasses.bottom] || 'pb-4';

  const renderBackground = () => {
    const gradientClass = backgroundTypeClasses[backgroundType];

    if (Array.isArray(gradientClass)) {
      return (
        <>
          <div className={clsx('pointer-events-none absolute -z-10 h-full w-screen bg-cover bg-no-repeat', gradientClass[0])} />
          <div className={clsx('pointer-events-none absolute -z-10 h-full w-screen bg-cover bg-no-repeat', gradientClass[1])} />
        </>
      );
    }

    return gradientClass ? (
      <div className={clsx('pointer-events-none absolute left-0 top-0 -z-20 h-full w-screen bg-cover bg-no-repeat', gradientClass)} />
    ) : null;
  };

  const handleShowToggle = () => {
    setShowAll(prev => !prev);
    if (showAll) {
      setTimeout(() => {
        sectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    }
  };

  // Filter out the current detail slug from the cards collection so it doesn't show up in the next up listing in the detail pages
  const filteredCardsCollection = cardsCollection?.items.filter((items: any) => {
    if (params?.detailSlug) {
      const splitSlug = items?.slug.split('/');
      const [, , detailSlug] = splitSlug || [];
      console.log('detailSlug', detailSlug);
      return detailSlug !== params?.detailSlug;
    }
    return cardsCollection?.items;
  });

  return (
    <section ref={sectionRef} className={`relative flex flex-col gap-5 sm:gap-10 ${paddingTop} ${paddingBottom} scroll-mt-10`}>
      {/* SVG lines */}
      {showGridLines && (
        <div className="absolute left-0 top-0 -z-10 h-full w-full text-WHITE">
          <SvgLinesComponent />
        </div>
      )}
      {/* Background */}
      {renderBackground()}

      {heading && <Heading content={{ heading: heading as HeadingComponentData }} />}

      {/* Listing Cards */}
      <AnimatePresence mode="wait">
        <motion.article layout className="container grid grid-cols-12 gap-x-8 gap-y-20 sm:gap-x-0 sm:gap-y-15">
          {showAll
            ? filteredCardsCollection?.map((card: any, index: number) => {
                return <ListingCard key={`${card._id}-${index}`} card={card} />;
              })
            : filteredCardsCollection.slice(0, numberOfCardsToShow).map((card: any, index: number) => {
                return <ListingCard key={`${card._id}-${index}`} card={card} />;
              })}
        </motion.article>
      </AnimatePresence>

      {/* Show More Button */}
      {filteredCardsCollection?.length > numberOfCardsToShow && (
        <motion.article
          layout="position"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="col-span-full mt-20 flex justify-center sm:mt-10"
        >
          <AnimatedButton
            showAll={showAll}
            onClick={handleShowToggle}
            className="border-2 border-[#6100FF] bg-DEEPPURPLE/10 px-40px py-10px text-[1rem] text-[#6100FF] hover:bg-PURP/10"
          />
        </motion.article>
      )}
    </section>
  );
}

function ListingCard({ card }: { card: ListingCardProps }) {
  const { title, featuredImage, route, slug, url } = card;
  const href: string = getHref(route, slug, url);
  const { imageLoaded, imageRef } = useImageLoad();
  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className="col-span-4 md:col-span-6 sm:col-span-full"
    >
      <Link href={href || '#'} className="group flex flex-col gap-y-4 text-WHITE">
        <div className="relative h-full w-full">
          {!imageLoaded && <LoadingSpinner className="h-15 w-15" />}
          {featuredImage ? (
            <Image
              ref={imageRef}
              src={featuredImage.url}
              alt={featuredImage.description || featuredImage.title || ''}
              width={featuredImage.width || 500}
              height={featuredImage.height || 500}
              sizes="(max-width: 768px) 100vw, 768px"
              draggable={false}
              className={clsx(
                'aspect-video rounded-md border border-WHITE/20 object-cover transition-all duration-500',
                imageLoaded ? 'opacity-100' : 'animate-pulse border-2 bg-OFF-BLACK opacity-0',
              )}
            />
          ) : (
            <Image
              src="/assets/placeholder.svg"
              alt="placeholder"
              width={500}
              height={500}
              sizes="(max-width: 768px) 100vw, 768px"
              draggable={false}
              className="aspect-video h-full w-full rounded-md bg-green-900 object-cover"
            />
          )}
        </div>
        <h2 className="relative line-clamp-3 text-H4">
          <span className="inline bg-gradient-to-r from-WHITE to-WHITE bg-[size:0%_0px] bg-[position:0_100%] bg-no-repeat leading-tight transition-all delay-100 duration-500 ease-underline-bezier group-hover:bg-[size:100%_1px]">
            {title}
          </span>
        </h2>
      </Link>
    </motion.div>
  );
}

// Animates label text based on showAll prop
function AnimatedButton({ showAll, onClick, className }: any) {
  return (
    <Button className={`relative overflow-hidden ${className}`} onClick={onClick}>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.3 }}>
        {showAll ? 'Show Less' : 'Show More'}
      </motion.div>
    </Button>
  );
}
