import { ContentfulLivePreview } from '@contentful/live-preview';

import { ContentfulImage } from '@/app/types/contentful/types';

import ClientCTAButton from './Buttons/ClientCTAButton';

type LandingProps = {
  title: string;
  subtitle: string;
  ctaButton: any;
  heroImageDesktop: ContentfulImage;
  heroImageMobile: ContentfulImage;
  id: string;
};

export default function Landing({ title, subtitle, ctaButton, id }: LandingProps) {
  return (
    <>
      <article className="col-span-12 row-start-1 mt-15 flex animate-fade-in-up flex-col items-center justify-center space-y-6 self-center md:col-span-full md:mt-10">
        <h1
          {...ContentfulLivePreview.getProps({ entryId: id, fieldId: 'title' })}
          className="text-center text-Hero-Display text-WHITE md:text-Display-Mobile"
        >
          {title}
        </h1>
        <p className="max-w-[80ch] text-balance text-center text-Body-Large text-WHITE/75" style={{ animationDelay: '0.2s' }}>
          {subtitle}
        </p>
        {ctaButton && <ClientCTAButton buttonData={ctaButton} className="z-10 col-span-full row-start-1 mt-8 sm:text-xs" />}
      </article>
    </>
  );
}
