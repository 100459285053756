'use client';
import { useState } from 'react';

import type { ContentfulUseCasesCardsComponent } from '@/app/types/contentful/types';

import Button from '../Interactive/Button';
import { UseCaseCardContainer } from './UseCaseCardContainer';

const UseCasesCardsComponent = ({ content }: { content: ContentfulUseCasesCardsComponent }) => {
  const [showAll, setShowAll] = useState<boolean>(false);
  const numberOfCards = content.numberOfCards || 0;
  const cardsCollectionArrayLength = content.cardsCollection?.items?.length || 0;

  return (
    <section className="container relative col-span-full grid grid-cols-12 place-items-center gap-5 pb-20 pt-10 md:pt-20">
      <UseCaseCardContainer content={content} showAll={showAll} />
      <article className="col-span-full row-start-3 mt-10">
        {cardsCollectionArrayLength > numberOfCards && (
          <Button
            onClick={() => setShowAll(prev => !prev)}
            className="bg-GREEN-500 px-20px py-10px hover:bg-GREEN"
          >
            {showAll ? 'Show Less Use Cases' : 'Show All Use Cases'}
          </Button>
        )}
      </article>
    </section>
  );
};



export default UseCasesCardsComponent;
