'use client';

import { motion } from 'motion/react';
import Image from 'next/image';
import React, { useMemo } from 'react';

const AnimatedToggler: React.FC<{
  items: any[];
  activeIndex: number;
  onToggle: (index: number) => void;
}> = React.memo(({ items, activeIndex, onToggle }) => {
  const MemoizedButtons = useMemo(() => {
    return items.map((item, index) => (
      <motion.button
        key={item.buttonTitle}
        className='relative flex min-w-[200px] cursor-pointer items-center justify-center rounded-lg px-6 py-3 text-[1.5rem] transition-all duration-75 ease-linear'
        onClick={() => onToggle(index)}
        animate={{
          color: index === activeIndex ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)',
          backgroundColor: index === activeIndex ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0)',
        }}
        whileHover={{ color: 'rgba(255, 255, 255, 1)', backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
        transition={{ duration: 0.2 }}
      >
        {item.buttonIcon?.url && (
          <Image
            src={item.buttonIcon.url}
            alt={item.buttonIcon.description || item.buttonIcon.title}
            width={25}
            height={25}
            sizes="25px"
            draggable={false}
            className="mr-3 inline h-auto w-auto min-w-[25px] object-contain"
          />
        )}
        <span>{item.buttonTitle}</span>
      </motion.button>
    ));
  }, [items, activeIndex, onToggle]);

  return (
    <motion.div className="relative mt-20 flex w-full max-w-[1440px] justify-center rounded-lg p-2" transition={{ duration: 0.3 }}>
      <div className="relative z-10 flex flex-wrap justify-center gap-2">{MemoizedButtons}</div>
    </motion.div>
  );
});

AnimatedToggler.displayName = 'AnimatedToggler';

export default AnimatedToggler;
