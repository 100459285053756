export default function RSAHappyHourFormInputs({
  handleSubmit,
  onSubmit,
  onError,
  errors,
  register,
  isSubmitting,
  submittedMessage,
  serverError,
}: {
  handleSubmit: any;
  onSubmit: any;
  onError: any;
  errors: any;
  register: any;
  isSubmitting: any;
  submittedMessage: any;
  serverError: any;
}): JSX.Element {
  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className="RSA-Happy-Hour-Form">
      <section className="mx-auto w-full max-w-lg px-8 md:px-4">
        {/* First Name */}
        <div className="mb-4">
          <label className="text-sm mb-2 block font-bold text-gray-300" htmlFor="firstname">
            First Name*
          </label>
          <input
            aria-label="Enter First Name"
            className={`mb-3 block w-full appearance-none rounded-md border bg-SLATE_BLUE px-4 py-3 leading-tight text-gray-300 focus:bg-gray-600 focus:outline-none ${
              errors.firstname ? 'border-red-500' : 'border-gray-600'
            }`}
            id="firstname"
            type="text"
            placeholder="John"
            {...register('firstname')}
          />
          {errors.firstname && <p className="mt-2 text-xs italic text-red-500">{errors.firstname.message}</p>}
        </div>

        {/* Last Name */}
        <div className="mb-4">
          <label className="text-sm mb-2 block font-bold text-gray-300" htmlFor="lastname">
            Last Name*
          </label>
          <input
            aria-label="Enter Last Name"
            className={`mb-3 block w-full appearance-none rounded-md border bg-SLATE_BLUE px-4 py-3 leading-tight text-gray-300 focus:bg-gray-600 focus:outline-none ${
              errors.lastname ? 'border-red-500' : 'border-gray-600'
            }`}
            id="lastname"
            type="text"
            placeholder="Doe"
            {...register('lastname')}
          />
          {errors.lastname && <p className="mt-2 text-xs italic text-red-500">{errors.lastname.message}</p>}
        </div>

        {/* Email */}
        <div className="mb-4">
          <label className="text-sm mb-2 block font-bold text-gray-300" htmlFor="email">
            Email*
          </label>
          <input
            aria-label="Enter Email"
            className={`mb-3 block w-full appearance-none rounded-md border bg-SLATE_BLUE px-4 py-3 leading-tight text-gray-300 focus:bg-gray-600 focus:outline-none ${
              errors.email ? 'border-red-500' : 'border-gray-600'
            }`}
            id="email"
            type="email"
            placeholder="email@company.com"
            {...register('email')}
          />
          {errors.email && <p className="mt-2 text-xs italic text-red-500">{errors.email.message}</p>}
        </div>

        {/* Mobile Phone */}
        <div className="mb-4">
          <label className="text-sm mb-2 block font-bold text-gray-300" htmlFor="mobilephone">
            Mobile Phone*
          </label>
          <input
            aria-label="Enter Mobile Phone"
            className={`mb-3 block w-full appearance-none rounded-md border bg-SLATE_BLUE px-4 py-3 leading-tight text-gray-300 focus:bg-gray-600 focus:outline-none ${
              errors.mobilephone ? 'border-red-500' : 'border-gray-600'
            }`}
            id="mobilephone"
            type="tel"
            placeholder="+1234567890"
            {...register('mobilephone')}
          />
          {errors.mobilephone && <p className="mt-2 text-xs italic text-red-500">{errors.mobilephone.message}</p>}
        </div>

        {/* Company */}
        <div className="mb-6">
          <label className="text-sm mb-2 block font-bold text-gray-300" htmlFor="company">
            Company*
          </label>
          <input
            aria-label="Enter Company Name"
            className={`mb-3 block w-full appearance-none rounded-md border bg-SLATE_BLUE px-4 py-3 leading-tight text-gray-300 focus:bg-gray-600 focus:outline-none ${
              errors.company ? 'border-red-500' : 'border-gray-600'
            }`}
            id="company"
            type="text"
            placeholder="Company Name"
            {...register('company')}
          />
          {errors.company && <p className="mt-2 text-xs italic text-red-500">{errors.company.message}</p>}
        </div>

        {/* Server Error */}
        {serverError && <p className="mt-2 text-xs italic text-red-500">{serverError}</p>}

        {/* Submit Button */}
        <div className="flex items-center justify-between pt-4">
          <button
            aria-label="Submit"
            className="focus:shadow-outline relative w-full rounded-md border-2 border-GREEN-500 bg-DEEPGREEN py-2.5 text-xs font-bold text-WHITE transition-colors duration-200 hover:bg-DEEPGREEN-500 disabled:opacity-50"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? <SubmitLoader /> : submittedMessage ? submittedMessage : 'Submit'}
          </button>
        </div>
      </section>
    </form>
  );
}

function SubmitLoader(): JSX.Element {
  return (
    <div className="flex items-center justify-center gap-1 py-1">
      <span className="sr-only">Loading...</span>
      <div className="h-1.5 w-1.5 animate-bounce rounded-full bg-WHITE/80 [animation-delay:-0.3s]" />
      <div className="h-1.5 w-1.5 animate-bounce rounded-full bg-WHITE/80 [animation-delay:-0.15s]" />
      <div className="h-1.5 w-1.5 animate-bounce rounded-full bg-WHITE/80" />
    </div>
  );
}
