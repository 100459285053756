'use client';

import { motion } from 'motion/react';

import Button from '../Interactive/Button';

export default function AnimatedButton({ showAll, onClick, className }: { showAll: boolean; onClick: () => void; className: string }) {
  return (
    <Button className={`relative overflow-hidden ${className}`} onClick={onClick}>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.3 }}>
        {showAll ? 'Show Less' : 'Show More'}
      </motion.div>
    </Button>
  );
}