import clsx from 'clsx';
import Image from 'next/image';
import { useInView } from 'react-intersection-observer';

import type { ContenfulProductCarouselItem } from "@/app/types/contentful/types";

import LinkButton from '../Buttons/LinkButton';


export default function ProductCarouselItemMobile({ item, index, setActiveIndex }: { item: ContenfulProductCarouselItem; index: number; setActiveIndex: (index: number) => void }) {
  const { ref } = useInView({
    threshold: 0.5,
    onChange: inView => {
      if (inView) {
        setActiveIndex(index);
      }
    },
  });

  return (
    <div
      ref={ref}
      className={clsx(
        'flex h-[627px] flex-[0_0_325px] snap-center flex-col gap-5 rounded-lg border border-WHITE/40 p-5',
        { 'bg-BLUE-950': index === 0 },
        { 'bg-GREEN-950': index === 1 },
        { 'bg-PURP-950': index === 2 },
        { 'bg-BLUE-950': index === 3 },
      )}
    >
      {/* Title Icon */}
      <div className="flex w-full flex-wrap">
        {item.buttonIcon?.url && (
          <Image
            src={item.buttonIcon.url}
            alt={item.buttonIcon.description || item.buttonIcon.title}
            width={item.buttonIcon.width}
            height={item.buttonIcon.height}
            sizes="(max-width: 25px) 100vw, 25px"
            draggable={false}
            className="h-[28px] w-[28px] object-contain mr-2"
          />
        )}
        <p
          className={clsx('text-[1.25rem] font-bold', {
            'text-[#8ABEFF]': item.buttonTitle === 'Intel Exchange' || index === 0,
            'text-[#5FEC96]': item.buttonTitle === 'Collaborate' || index === 1,
            'text-[#F19CFF]': item.buttonTitle === 'Respond' || index === 2,
            'text-[#83CAFF]': item.buttonTitle === 'Orchestrate' || index === 3,
          })}
        >
          {item.buttonTitle}
        </p>
      </div>
      {/* Item Title */}
      <div className="flex flex-col text-WHITE">
        <span className="h-[144px]">
          <h1 className="text-H1-Mobile font-bold">{item.itemTitleMobile}</h1>
        </span>
        <p className="pt-5 mb-5">{item.itemDescription}</p>
        <LinkButton
          hrefData={item}
          label={item.linkLabel}
          buttonType="Primary"
          className='w-fit'
        />
      </div>
      {/* Item Image */}
      <div className="mb-6 mt-auto w-full">
        {item.featuredImage?.url && (
          <Image
            src={item.featuredImage.url}
            alt={item.featuredImage.description || item.featuredImage.title}
            width={item.featuredImage.width}
            height={item.featuredImage.height}
            sizes="(max-width: 768px) 100vw, 768px"
            draggable={false}
            className="object-contain"
          />
        )}
      </div>
    </div>
  );
}
