export default function BookADemoFormInputs({
  handleSubmit,
  onSubmit,
  onError,
  errors,
  register,
  isSubmitting,
  submittedMessage,
  serverError,
}: {
  handleSubmit: any;
  onSubmit: any;
  onError: any;
  errors: any;
  register: any;
  isSubmitting: any;
  submittedMessage: any;
  serverError: any;
}): JSX.Element {
  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className="RSA-Conference-Book-A-Demo-Form-non-Hubspot">
      <section className="mx-auto w-full max-w-lg px-8 md:px-4">
        {/*//! First Name and Last Name */}
        <div className="-mx-3 mb-6 flex">
          <div className="w-full flex-1 px-3">
            <label className="text-sm mb-2 block font-bold text-gray-300" htmlFor="firstname">
              First name*
            </label>
            <input
              aria-label="Enter First Name"
              className={`block w-full appearance-none rounded-md border bg-SLATE_BLUE px-4 py-3 leading-tight text-gray-300 focus:bg-gray-600 focus:outline-none ${
                errors.firstname ? 'border-red-500' : 'border-gray-600'
              }`}
              id="firstname"
              type="text"
              placeholder="Enter First Name"
              {...register('firstname')}
              // ? What register does is it takes the name of the input field and registers it with the form.
            />
            {errors.firstname && <p className="mt-2 text-xs italic text-red-500">{errors.firstname.message}</p>}
          </div>
          <div className="w-full flex-1 px-3">
            <label className="text-sm mb-2 block font-bold text-gray-300" htmlFor="lastname">
              Last name*
            </label>
            <input
              aria-label="Enter Last Name"
              className={`block w-full appearance-none rounded-md border bg-SLATE_BLUE px-4 py-3 leading-tight text-gray-300 focus:bg-gray-600 focus:outline-none ${
                errors.lastname ? 'border-red-500' : 'border-gray-600'
              }`}
              id="lastname"
              type="text"
              placeholder="Enter Last Name"
              {...register('lastname')}
            />
            {errors.lastname && <p className="mt-2 text-xs italic text-red-500">{errors.lastname.message}</p>}
          </div>
        </div>
        {/* // ! Region */}
        <div className="mb-6">
          <label className="text-sm mb-2 block font-bold text-gray-300" htmlFor="region__c">
            Region*
          </label>
          <div className="relative">
            <select
              aria-label="Select your Region"
              className={`block w-full appearance-none rounded-md border bg-SLATE_BLUE px-4 py-3 pr-10 leading-tight text-gray-300 focus:bg-gray-600 focus:outline-none bg-no-repeat bg-[right_0.75rem_center] ${
                errors.region ? 'border-red-500' : 'border-gray-600'
              }`}
              id="region__c"
              {...register('region__c')}
              style={{
                backgroundImage: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%239CA3AF' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E\")",
                backgroundSize: '1.5em'
              }}
            >
              <option value="" disabled>Select your Region</option>
              <option value="NA">North America</option>
              <option value="APAC">APAC</option>
              <option value="India-SAARC">India & SAARC</option>
              <option value="EMEA">EMEA</option>
            </select>
          </div>
          {errors.region && <p className="mt-2 text-xs italic text-red-500">{errors.region.message}</p>}
        </div>
        {/* //! Business Email */}
        <div className="mb-6">
          <label className="text-sm mb-2 block font-bold text-gray-300" htmlFor="email">
            Business email*
          </label>
          <input
            aria-label="Enter Business Email"
            className={`mb-3 block w-full appearance-none rounded-md border bg-SLATE_BLUE px-4 py-3 leading-tight text-gray-300 focus:bg-gray-600 focus:outline-none ${
              errors.email ? 'border-red-500' : 'border-gray-600'
            }`}
            id="email"
            type="email"
            placeholder="email@company.com"
            {...register('email')}
          />
          {errors.email && <p className="mt-2 text-xs italic text-red-500">{errors.email.message}</p>}
        </div>
        {/* //! Phone Number */}
        <div className="mb-6">
          <label className="text-sm mb-2 block font-bold text-gray-300" htmlFor="phone">
            Phone number*
          </label>
          <input
            aria-label="Enter Phone Number"
            className={`mb-3 block w-full appearance-none rounded-md border bg-SLATE_BLUE px-4 py-3 leading-tight text-gray-300 focus:bg-gray-600 focus:outline-none ${
              errors.phone ? 'border-red-500' : 'border-gray-600'
            }`}
            id="phone"
            type="tel"
            placeholder="Enter phone number"
            {...register('phone')}
          />
          {errors.phone && <p className="mt-2 text-xs italic text-red-500">{errors.phone.message}</p>}
        </div>
        {/* //! Company Name */}
        <div className="mb-6">
          <label className="text-sm mb-2 block font-bold text-gray-300" htmlFor="company">
            Company name*
          </label>
          <input
            aria-label="Enter Company Name"
            className={`mb-3 block w-full appearance-none rounded-md border bg-SLATE_BLUE px-4 py-3 leading-tight text-gray-300 focus:bg-gray-600 focus:outline-none ${
              errors.company ? 'border-red-500' : 'border-gray-600'
            }`}
            id="company"
            type="text"
            placeholder="Enter company name"
            {...register('company')}
          />
          {errors.company && <p className="mt-2 text-xs italic text-red-500">{errors.company.message}</p>}
        </div>
        {/* //! Job Title */}
        <div className="mb-6">
          <label className="text-sm mb-2 block font-bold text-gray-300" htmlFor="jobtitle">
            Job title*
          </label>
          <input
            aria-label="Enter Job Title"
            className={`mb-3 block w-full appearance-none rounded-md border bg-SLATE_BLUE px-4 py-3 leading-tight text-gray-300 focus:bg-gray-600 focus:outline-none ${
              errors.jobtitle ? 'border-red-500' : 'border-gray-600'
            }`}
            id="jobtitle"
            type="text"
            placeholder="Enter job title"
            {...register('jobtitle')}
          />
          {errors.jobtitle && <p className="mt-2 text-xs italic text-red-500">{errors.jobtitle.message}</p>}
        </div>
        {/* //! How did you hear about us? */}
        <div className="mb-6">
          <label className="text-sm mb-2 block font-bold text-gray-300" htmlFor="how_did_you_hear_about_us_">
            How did you hear about us?*
          </label>
          <input
            aria-label="Enter How did you hear about us?"
            className={`mb-3 block w-full appearance-none rounded-md border bg-SLATE_BLUE px-4 py-3 leading-tight text-gray-300 focus:bg-gray-600 focus:outline-none ${
              errors.how_did_you_hear_about_us_ ? 'border-red-500' : 'border-gray-600'
            }`}
            id="how_did_you_hear_about_us_"
            type="text"
            placeholder="Enter your response"
            {...register('how_did_you_hear_about_us_')}
          />
          {errors.how_did_you_hear_about_us_ && (
            <p className="mt-2 text-xs italic text-red-500">{errors.how_did_you_hear_about_us_.message}</p>
          )}
        </div>
        .{/* //! Display server error if any */}
        {serverError && <p className="mt-2 text-xs italic text-red-500">{serverError}</p>}
        {/* //! Submit Button */}
        <div className="flex items-center justify-between pt-4">
          <button
            aria-label="Request Demo"
            className="focus:shadow-outline relative w-full rounded-md border-2 border-GREEN-500 bg-DEEPGREEN py-2.5 text-xs font-bold text-WHITE transition-colors duration-200 hover:bg-DEEPGREEN-500 disabled:opacity-50"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? <SubmitLoader /> : submittedMessage ? submittedMessage : 'Request Demo'}
          </button>
        </div>
      </section>
    </form>
  );
}

function SubmitLoader(): JSX.Element {
  return (
    <div className="flex items-center justify-center gap-1 py-1">
      <span className="sr-only">Loading...</span>
      <div className="h-1.5 w-1.5 animate-bounce rounded-full bg-WHITE/80 [animation-delay:-0.3s]" />
      <div className="h-1.5 w-1.5 animate-bounce rounded-full bg-WHITE/80 [animation-delay:-0.15s]" />
      <div className="h-1.5 w-1.5 animate-bounce rounded-full bg-WHITE/80" />
    </div>
  );
}
