'use client';

import clsx from 'clsx';
import { AnimatePresence, motion } from 'motion/react';
import { useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import BlogListingCard from '@/app/components/ui/Blog/BlogListingCard';
import LoadingSpinner from '@/app/components/ui/Loader/LoadingSpinner';
import type { BlogListingCardProps, ClientBlogListingProps } from '@/app/types/contentful/types';

// Animation variants for staggered children animation
const stagger = {
  animate: {
    transition: {
      staggerChildren: 0.03, // Slight delay between each child animation
    },
  },
} as const;

export function ClientBlogListing({ blogPagesCollection, numberOfCards, totalBlogPages, title }: ClientBlogListingProps) {
  const [blogPages, setBlogPages] = useState(blogPagesCollection);
  const [isLoading, setIsLoading] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  // Load more cards when the user scrolls to the bottom of the page
  const loadMore = useCallback(async () => {
    if (isLoading || blogPages.length >= totalBlogPages) return;

    try {
      setIsLoading(true);
      // Calculate skip based on current number of items
      const skip = blogPages.length;
      const response = await fetch(`/api/blog-pages?skip=${skip}&limit=12`);
      const data = await response.json();

      setBlogPages(prev => [...prev, ...data.items]);
    } catch (error) {
      console.error('Error loading more blog pages:', error);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, blogPages.length, totalBlogPages]);

  useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [inView, loadMore]);

  return (
    <>
      <motion.article
        layout="position"
        variants={stagger}
        initial="initial"
        animate="animate"
        className={clsx(
          'col-span-full grid grid-cols-12 gap-x-8 gap-y-20 md:row-start-2 sm:gap-x-0 sm:gap-y-15',
          title ? 'row-start-2' : 'row-start-1',
        )}
      >
        <AnimatePresence>
          {blogPages.map((card: BlogListingCardProps, index: number) => (
            <BlogListingCard key={`${card.sys.id}-${index}`} card={card} initiallyVisible={index < numberOfCards} />
          ))}
        </AnimatePresence>
      </motion.article>

      {blogPages.length < totalBlogPages && (
        <div ref={ref} className="relative col-span-full row-start-3 h-20">
          <LoadingSpinner className="mx-auto h-10 w-10" />
        </div>
      )}
    </>
  );
}
