'use client';

import dynamic from 'next/dynamic';

import CardsComponent from '@/app/components/ui/Cards/CardsComponent';
import Landing from '@/app/components/ui/Landing';
import LogoComponent from '@/app/components/ui/LogoComponent';
import { SvgLinesComponent } from '@/app/components/ui/SvgLinesComponent';
import { paddingClasses } from '@/app/lib/utils/getPaddingClasses';

const ThreeCanvas = dynamic(() => import('./ThreeCanvas'), {
  ssr: false,
  loading: () => <div className="h-full w-full bg-BLACK" />, // Loading fallback
});

export default function HeroComponentTwoColumn({ content }: any) {
  const {
    heroComponentTwoColumn: {
      title,
      subtitle,
      ctaButton,
      gradientType,
      showGridLines,
      heroImageDesktop,
      heroImageMobile,
      spacingTop,
      spacingBottom,
      cardsComponent,
      logoComponent,
    },
    sys,
  } = content;

  // const variant = useFeatureFlagVariantKey(HERO_EXPERIMENT_NAME);

  const paddingTop = paddingClasses.top?.[spacingTop as keyof typeof paddingClasses.top] || 'pt-4';
  const paddingBottom = paddingClasses.bottom?.[spacingBottom as keyof typeof paddingClasses.bottom] || 'pb-4';

  return (
    <section className="relative">
      <div className={`relative grid h-[100dvh] w-full grid-cols-12 ${paddingTop} ${paddingBottom}`}>
        {/* Gradient Top */}
        {gradientType === 'Purple Gradient' ? (
          <div className="pointer-events-none absolute -z-10 h-full w-screen bg-gradient-top-purple bg-cover bg-top bg-no-repeat" />
        ) : gradientType === 'Blue Gradient' ? (
          <div className="pointer-events-none absolute -z-10 h-full w-screen bg-gradient-top-blue bg-cover bg-top bg-no-repeat" />
        ) : null}

        {/* SVG lines */}
        {showGridLines && <SvgLinesComponent />}

        {/* Home Landing */}
        <section className="container z-10 col-span-full grid h-fit grid-cols-12 sm:gap-y-0">
          <Landing
            id={sys.id}
            title={title}
            subtitle={subtitle}
            ctaButton={ctaButton}
            heroImageDesktop={heroImageDesktop}
            heroImageMobile={heroImageMobile}
          />
        </section>
      </div>

      {/* Cards Component */}
      {cardsComponent && (
        <div className="z-20 col-span-full" id="main-content">
          <CardsComponent content={{ cardsComponent }} />
        </div>
      )}

      {/* Logo Component */}
      {logoComponent && (
        <div className="z-20 col-span-full">
          <LogoComponent content={{ logoComponent }} />
        </div>
      )}

      {/* Desktop Canvas */}
      <ThreeCanvas className="absolute left-0 top-0 -z-10 h-full w-full md-lg:mt-20 md:hidden" position={[0, -0.5, 0]} scale={1.8} />

      {/* Mobile Canvas */}
      <ThreeCanvas className="absolute left-0 top-0 -z-10 hidden h-full w-full md:block sm:mt-20" position={[0, 1, 0]} scale={1} />
    </section>
  );
}