'use client';

import React, { useEffect, useState } from 'react';

type Job = {
  title: string;
  location: { name: string };
  branch?: { city: string; name: string };
  absolute_url: string;
  applicant_apply_link: string;
};

type Department = {
  name: string;
  jobs: Job[];
};

type Office = {
  name: string;
  departments: Department[];
};

export default function OpenPositions({ content }: { content?: any }) {
  const [jobsBoard, setJobsBoard] = useState<Office[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchJobs() {
      try {
        setIsLoading(true);
        setError(null);

        // Fetch jobs from all regions
        const [usResponse, indiaResponse] = await Promise.all([
          fetch('/api/jobs/us'),
          fetch('/api/jobs/india')
        ]);

        
        const [usData, indiaData] = await Promise.all([
          usResponse.json(),
          indiaResponse.json()
        ]);

        console.log('usData', usData);

        const offices = [];

        if (usData) {
          const usOffice = processUSJobs(usData);
          offices.push(usOffice);
        }

        if (indiaData) {
          const indiaOffice = processIndiaJobs(indiaData);
          offices.push(indiaOffice);
        }

        // Process other country jobs from the US data (since it contains all jobs)
        if (usData) {
          const otherOffice = processOtherCountryJobs(usData);
          if (otherOffice.departments.length > 0) {
            offices.push(otherOffice);
          }
        }

        if (offices.length === 0) {
          throw new Error('No job listings available.');
        }

        setJobsBoard(offices);
      } catch (err) {
        setError('Failed to fetch job listings. Please try again later.');
        console.error('Error fetching jobs:', err);
      } finally {
        setIsLoading(false);
      }
    }

    fetchJobs();
  }, []); // Only fetch once on component mount

  function processUSJobs(data: any): Office {
    console.log('Processing US Jobs - Raw Data:', data.jobs);
    
    const usaOffice: Office = {
      name: 'United States',
      departments: []
    };

    if (data?.jobs && Array.isArray(data.jobs)) {
      const deptMap: { [key: string]: Job[] } = {};

      data.jobs.forEach((job: any) => {
        const location = job.location.name.toLowerCase();
        console.log('Processing job:', job.title, 'Location:', location);
        
        // Process US jobs
        if (location.includes('united states')) {
          let deptName = 'Other';
          const title = job.title.toLowerCase();

          console.log('Job passed location filter:', job.title);

          // Updated department categorization logic
          if (title.includes('cybersecurity sales') || title.includes('sales engineer')) {
            deptName = 'Sales';
          } else if (title.includes('solutions architect')) {
            deptName = 'Solution Delivery';
          } else if (title.includes('engineer') || title.includes('sdet') || title.includes('developer')) {
            deptName = 'Engineering';
          } else if (title.includes('customer success')) {
            deptName = 'Customer Success';
          } else if (title.includes('sales') || title.includes('channel director')) {
            deptName = 'Sales';
          } else if (title.includes('product')) {
            deptName = 'Product';
          } else if (title.includes('risk') || title.includes('compliance')) {
            deptName = 'Operations';
          } else if (title.includes('administrative')) {
            deptName = 'Operations';
          }

          console.log('Job categorized as:', deptName);

          if (!deptMap[deptName]) {
            deptMap[deptName] = [];
          }

          deptMap[deptName].push({
            title: job.title,
            location: { name: job.location.name },
            absolute_url: job.absolute_url,
            applicant_apply_link: job.absolute_url
          });
        } else {
          console.log('Job filtered out due to location:', job.title);
        }
      });

      // Convert department map to array and sort
      usaOffice.departments = Object.entries(deptMap)
        .map(([name, jobs]) => ({ name, jobs }))
        .filter(dept => dept.jobs.length > 0)
        .sort((a, b) => a.name.localeCompare(b.name));

      console.log('Final US Office departments:', usaOffice.departments);
    }

    return usaOffice;
  }

  function processIndiaJobs(data: any): Office {
    const indiaOffice: Office = {
      name: 'India',
      departments: []
    };

    if (Array.isArray(data)) {
      const deptMap: { [key: string]: Job[] } = {};

      data.forEach((job: any) => {
        if (job.branch?.country_code === 'IN' || job.branch?.city?.includes('Bengaluru')) {
          const deptName = job.department?.name || 'Other';

          if (!deptMap[deptName]) {
            deptMap[deptName] = [];
          }

          deptMap[deptName].push({
            title: job.title,
            location: {
              name: job.branch ? `${job.branch.city}, ${job.branch.name}` : 'India'
            },
            absolute_url: job.applicant_apply_link,
            applicant_apply_link: job.applicant_apply_link
          });
        }
      });

      // Convert department map to array and sort
      indiaOffice.departments = Object.entries(deptMap)
        .map(([name, jobs]) => ({ name, jobs }))
        .filter(dept => dept.jobs.length > 0)
        .sort((a, b) => a.name.localeCompare(b.name));
    }

    return indiaOffice;
  }

  function processOtherCountryJobs(data: any): Office {
    const otherOffice: Office = {
      name: 'Other Countries',
      departments: []
    };

    if (data?.jobs && Array.isArray(data.jobs)) {
      const deptMap: { [key: string]: Job[] } = {};

      data.jobs.forEach((job: any) => {
        const location = job.location.name.toLowerCase();
        const title = job.title.toLowerCase();

        // Check if this is a non-US job or an international remote job
        const isUSJob = location.includes('united states') && !location.includes('emea') && !location.includes('apac');
        const isIndiaJob = location.includes('india');
        const isGenericRemote = location === 'remote';
        const isInternationalRemote = location.includes('remote') && 
          (location.includes('emea') || location.includes('apac') || location.includes('united kingdom'));
        
        const isOtherCountryJob = (!isUSJob && !isIndiaJob && !isGenericRemote) || isInternationalRemote;

        if (isOtherCountryJob) {
          let deptName = 'Other';

          // Updated department categorization logic
          if (title.includes('solutions architect')) {
            deptName = 'Solution Delivery';
          } else if (title.includes('engineer') || title.includes('sdet') || title.includes('developer')) {
            deptName = 'Engineering';
          } else if (title.includes('customer success')) {
            deptName = 'Customer Success';
          } else if (title.includes('sales') || title.includes('channel director')) {
            deptName = 'Sales';
          } else if (title.includes('product')) {
            deptName = 'Product';
          } else if (title.includes('risk') || title.includes('compliance')) {
            deptName = 'Operations';
          }

          if (!deptMap[deptName]) {
            deptMap[deptName] = [];
          }

          deptMap[deptName].push({
            title: title,
            location: { name: job.location.name },
            absolute_url: job.absolute_url,
            applicant_apply_link: job.absolute_url
          });
        }
      });

      otherOffice.departments = Object.entries(deptMap)
        .map(([name, jobs]) => ({ name, jobs }))
        .filter(dept => dept.jobs.length > 0)
        .sort((a, b) => {
          if (a.name === 'Other') return 1;
          if (b.name === 'Other') return -1;
          return a.name.localeCompare(b.name);
        });
    }

    return otherOffice;
  }

  if (isLoading) {
    return <div>Loading job listings...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }


  // console.log('jobsBoard', jobsBoard);
  // console.log('processUsaJobs', processUsaJobs);
  // console.log('processIndiaJobs', processIndiaJobs);
  // console.log('processOtherCountryJobs', processOtherCountryJobs);

  return (
    <section className="open-positions py-10">
      <div className="container mx-auto px-4">
        <h1 className="my-10 animate-fade-in-up text-balance text-center text-Display text-WHITE lg:text-H1 lg:font-extrabold md:text-Display-Mobile sm:text-H2-Mobile">
          {content?.title || 'Open Positions'}
        </h1>
        <div className="cy-career-jobs">
          <div className="overflow-hidden rounded-xl bg-white/5 shadow-lg">
            <div className="mb-2 mt-8 flex items-center justify-center gap-x-2 overflow-x-auto">
              {jobsBoard.map((office, oIndex) => (
                <button
                  key={oIndex}
                  className={`tab flex-shrink-0 rounded-full px-6 py-3 text-[1.25rem] font-medium transition-all duration-300 even:mx-1 sm:flex-shrink sm:px-4 sm:py-2 sm:text-[1rem] ${activeTab === oIndex ? 'bg-GREEN-500 text-BLACK' : 'bg-transparent text-WHITE hover:bg-GREEN/50'
                    }`}
                  onClick={() => setActiveTab(oIndex)}
                >
                  {office.name}
                </button>
              ))}
            </div>
            <div className="tab-content p-6">
              {jobsBoard[activeTab]?.departments.some(dept => dept.jobs && dept.jobs.length > 0) ? (
                jobsBoard[activeTab]?.departments.map(
                  (department, dIndex) =>
                    department.jobs &&
                    department.jobs.length > 0 && (
                      <div key={dIndex} className="animate-fadeIn mb-12 last:mb-0">
                        <h3 className="mb-6 text-H4 font-bold text-GREEN-500">{department.name}</h3>
                        <div className="grid gap-6 sm:gap-4">
                          {department.jobs.map((job, jIndex) => (
                            <div
                              key={jIndex}
                              className="rounded-lg bg-BLUE/10 p-6 shadow-md shadow-BLUE/20 transition-all duration-200 ease-in focus-within:ring-2 focus-within:ring-GREEN-500 focus-within:ring-opacity-50 hover:shadow-lg hover:shadow-BLUE/25 active:shadow-lg"
                            >
                              <div className="flex flex-wrap items-center justify-between">
                                <div className="mb-4 w-full lg:mb-0 lg:w-2/3">
                                  <h4 className="mb-2 text-[1.25rem] font-semibold text-WHITE md:text-[1rem] sm:max-w-[20ch] sm:text-[0.8rem]">
                                    {job.title}
                                  </h4>
                                  <p className="text-WHITE/70 sm:text-[0.8rem]">
                                    {job.location.name}
                                  </p>
                                </div>
                                <div className="w-full text-right lg:w-1/3">
                                  <a
                                    href={job.absolute_url || job.applicant_apply_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="hover:bg-GREEN-400 active:bg-GREEN-600 inline-block transform whitespace-nowrap rounded-full bg-GREEN-500 px-6 py-3 font-semibold text-BLACK transition-colors duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-GREEN-500 focus:ring-opacity-50 sm:px-4 sm:py-2 sm:text-[0.6rem]"
                                  >
                                    Apply Now
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ),
                )
              ) : (
                <p className="mx-auto mb-7 max-w-[50ch] text-center text-[1.25rem] font-medium text-WHITE/80 sm:mb-2 sm:max-w-[40ch] sm:text-[1rem]">
                  No open positions available for {jobsBoard[activeTab]?.name} at the moment.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}