'use client';

import { AnimatePresence } from 'motion/react'
import React, { useCallback, useMemo, useState } from 'react'

import AnimatedToggler from './AnimatedToggler'
import ProductCarouselItemDesktop from './ProductCarouselItemDesktop'

export default function CarouselDesktop({carouselTitle, carouselDescription, carouselItemsCollection}: any) {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const bgColors: string[] = useMemo(() => ['#0C195A', '#012D23', '#4D005C', '#0C195A'], []);


    const handleToggle = useCallback((index: number) => {
        setActiveIndex(index);
      }, []);
    
  return (
    <article className="col-span-full mt-20 flex w-full max-w-[1440px] flex-col items-center justify-center justify-self-center sm:hidden">
    <div className="w-full max-w-[800px] text-center">
      <h2 className="pb-4 text-Body font-semibold tracking-normal text-GREEN-500">{carouselTitle}</h2>
      <p className="text-balance text-[1.5rem] font-light tracking-normal text-WHITE sm:text-Body-Large">{carouselDescription}</p>
    </div>

    <AnimatedToggler items={carouselItemsCollection.items} activeIndex={activeIndex} onToggle={handleToggle} />

    <div
      className="relative mt-8 h-[650px] w-full max-w-[1438px] overflow-hidden rounded-lg border border-WHITE/40"
      style={{ backgroundColor: bgColors[activeIndex] }}
    >
      <AnimatePresence mode="wait">
        {carouselItemsCollection.items.map(
          (item: any, index: number) =>
            activeIndex === index && <ProductCarouselItemDesktop key={index} item={item} bgColor={bgColors[index]} />,
        )}
      </AnimatePresence>
    </div>
  </article>
  )
}
