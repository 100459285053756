import clsx from 'clsx';
import Image from 'next/image';

import ClientCTAButton from '@/app/components/ui/Buttons/ClientCTAButton';
import type { ContentfulHeadingComponent } from '@/app/types/contentful/types';

export default function Heading({ content }: ContentfulHeadingComponent) {
  return (
    <section
      className={clsx('container relative grid grid-cols-12 gap-y-40 overflow-hidden rounded-lg sm:grid-rows-[1fr]', {
        'grid-rows-[624px] sm:grid-rows-[80dvh]': content?.heading?.showBackgroundImage,
        'pb-30 pt-30 sm:pb-20 sm:pt-20': content?.heading?.spacing === 'Large',
        'pb-20 pt-20 sm:pb-10 sm:pt-10': content?.heading?.spacing === 'Medium',
        'pb-10 pt-10 sm:pb-5 sm:pt-5': content?.heading?.spacing === 'Small',
        'pb-5 pt-5': content?.heading?.spacing === 'Default',
        'pb-0 pt-0': content?.heading?.spacing === 'None',
      })}
    >
      <article
        className={clsx(
          'z-10 col-span-full row-start-1 flex w-full flex-col items-start justify-center gap-y-2 rounded-lg text-WHITE md:col-span-full',
          {
            'items-center justify-self-center text-center': content?.heading?.centerComponent,
            'self-center pl-5': content?.heading?.showBackgroundImage,
          },
        )}
      >
        {content?.heading?.label && <h1 className="text-[1rem] font-semibold text-GREEN-500">{content?.heading?.label}</h1>}
        {content?.heading?.title && (
          <p
            className={clsx('mb-2 text-Display font-bold md:text-H2 sm:text-[calc(2rem+0.5vw)]', {
              'text-balance text-Display': content?.heading?.titleFontSize === 'Display',
              'max-w-[25ch] text-H1': content?.heading?.titleFontSize === 'H1' || content?.heading?.titleFontSize === null,
              'max-w-[25ch] text-balance text-H2': content?.heading?.titleFontSize === 'H2',
              'max-w-[25ch] text-balance text-H3': content?.heading?.titleFontSize === 'H3',
            })}
          >
            {content?.heading?.title}
          </p>
        )}
        {content?.heading?.description && (
          <p
            className={clsx('w-full max-w-[80ch] text-pretty', {
              'text-Body-Large': content?.heading?.boldDescription,
              'text-Body': !content?.heading?.boldDescription,
            })}
          >
            {content?.heading?.description}
          </p>
        )}
        {/* CTA Buttons */}
        {content?.heading?.ctaButton && <ClientCTAButton buttonData={content.heading.ctaButton} className="mt-5" />}
      </article>
      {content?.heading?.showBackgroundImage && content?.heading?.backgroundImage && (
        <article className="absolute inset-0 -z-10 col-span-full row-start-1 h-full w-full self-center overflow-hidden rounded-lg text-WHITE">
          <Image
            src={content?.heading?.backgroundImage.url}
            alt={content?.heading?.backgroundImage.description || content?.heading?.backgroundImage.title}
            fill
            sizes="100vw"
            draggable={false}
            className="object-cover"
          />
        </article>
      )}
    </section>
  );
}
