import clsx from 'clsx';

import { getHref } from '@/app/lib/utils/getHref';
import type { ContentfulUseCasesCard, ContentfulUseCasesCardsComponent } from '@/app/types/contentful/types';

import LinkComponent from '../Interactive/Link';

export function UseCaseCardContainer({ content, showAll }: { content: ContentfulUseCasesCardsComponent; showAll: boolean }) {
  const { useCasesCardsComponent: { label, title, showTitleAndLabel, numberOfCards, cardsCollection } } = content;

  return (
    <>
      {showTitleAndLabel && (
        <article className="col-span-full max-w-[100ch] row-start-1 pt-24 text-center">
          <h2 className="text-Pill text-GREEN-500">{label}</h2>
          <h2 className="text-H1 text-WHITE md:text-H1-Mobile">{title}</h2>
        </article>
      )}

      <article className="col-start-2 col-end-12 grid auto-rows-[minmax(200px,_1fr)] grid-cols-12 gap-5 pt-10 md:auto-rows-[minmax(164px,_1fr)] md:pt-10 md:col-span-full sm:pt-0">
        {!showAll
          ? cardsCollection?.items &&
          cardsCollection.items
            .slice(0, numberOfCards)
            .map((card: ContentfulUseCasesCard, index: number) => <UseCaseCard key={`${card._id}-${index}`} card={card} />)
          : cardsCollection?.items &&
          cardsCollection.items.map((card: ContentfulUseCasesCard, index: number) => (
            <UseCaseCard key={`${card._id}-${index}`} card={card} />
          ))}
      </article>
    </>
  );
}

export function UseCaseCard({ card }: { card: ContentfulUseCasesCard }) {
  const { accentColor, label, title, description, route, slug, url } = card;
  const href: string = getHref(route, slug, url);

  const CardContent = () => (
    <article key={card._id} className="contents">
      <div className="col-span-5">
        {label && (
          <p
            className={clsx('mb-2 text-Body md:text-xs sm:text-xs', {
              'text-GREEN-500': accentColor === 'Green',
              'text-PURP': accentColor === 'Purple',
              'text-BLUE-400': accentColor === 'Blue',
            })}
          >
            {label}
          </p>
        )}
        {title && <h1 className="text-H3 text-WHITE lg:text-[1.5rem] md:text-[1.25rem]">{title}</h1>}
      </div>
      {href !== "null" && (
        <div className="col-start-6 row-start-1 self-start justify-self-end rounded-md border-none bg-WHITE/20 px-10px py-10px text-WHITE group-hover:bg-opacity-60 sm:px-[6px] sm:py-[6px]">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 12 12" fill="none">
          <path
            d="M1 11L10.9999 1M10.9999 1V10.6M10.9999 1H1.4"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          </svg>
        </div>
      )}
      <p className="col-span-full self-end text-Body text-WHITE/60 md:text-xs font-medium">{description}</p>
    </article>
  );

  if (href === "null") {
    return (
      <div
        className={clsx(
          'col-span-6 grid grid-cols-6 gap-4 rounded-md border-[1px] bg-OFF-BLACK p-5 md:col-span-full sm:p-10px',
          {
            'border-GREEN': accentColor === 'Green',
            'border-PURP': accentColor === 'Purple',
            'border-BLUE-400': accentColor === 'Blue',
          },
        )}
      >
        <CardContent />
      </div>
    );
  }

  return (
    <LinkComponent
      href={href}
      className={clsx(
        'col-span-6 grid grid-cols-6 gap-4 rounded-md border-[1px] bg-OFF-BLACK p-5 md:col-span-full sm:p-10px',
        'transition-colors duration-300 hover:bg-WHITE/5',
        {
          'border-GREEN': accentColor === 'Green',
          'border-PURP': accentColor === 'Purple',
          'border-BLUE-400': accentColor === 'Blue',
        },
      )}
    >
      <CardContent />
    </LinkComponent>
  );
}
