import { motion, Variants } from 'motion/react';
import { Link } from 'next-view-transitions';
import { InViewHookResponse, useInView } from 'react-intersection-observer';

import formatDate from '@/app/lib/utils/formatDate';
import { getHref } from '@/app/lib/utils/getHref';
import { ThreatListingCardProps } from '@/app/types/contentful/types';

// Animation variants for fading in and moving up
const fadeInUp: Variants = {
  initial: { opacity: 0, y: 20 }, // Start invisible and slightly below final position
  animate: { opacity: 1, y: 0 }, // Animate to fully visible and final position
  exit: { opacity: 0, y: -20 }, // Exit by fading out and moving slightly up
} as const;

export default function ThreatListingCard({ card, initiallyVisible }: { card: ThreatListingCardProps; initiallyVisible: boolean }) {
  const { title, route, slug, description, date } = card;
  const href: string = getHref(route, slug, null);
  const [ref, inView]: InViewHookResponse = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const cardVariants = initiallyVisible
    ? {
        initial: { opacity: 1, y: 0 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -20 },
      }
    : fadeInUp;

  const formattedDate = formatDate(date, 'short');

  return (
    <motion.div
      ref={ref}
      layout
      variants={cardVariants}
      initial="initial"
      animate={inView || initiallyVisible ? 'animate' : 'initial'}
      exit="exit"
      transition={{ duration: 0.3, ease: 'easeInOut', delay: initiallyVisible ? 0 : 0.1 }}
      className="col-span-4 md:col-span-6 sm:col-span-full"
    >
      <motion.div layout className="group flex flex-col gap-y-3 text-WHITE">
        <Link href={href || '#'} className="contents">
          <h1 className="text-H5 text-balance">{formattedDate}</h1>
          <h2 className="relative line-clamp-2 w-full whitespace-pre-wrap text-[1.45rem] font-bold">
            <span className="inline bg-gradient-to-r from-WHITE to-WHITE bg-[size:0%_0px] bg-[position:0_100%] bg-no-repeat leading-tight transition-all duration-500 ease-underline-bezier group-hover:bg-[size:100%_1px]">
              {title}
            </span>
          </h2>
          <p className="mt-3 line-clamp-3 text-pretty text-Body text-WHITE/60">{description || card.seoDescription}</p>
        </Link>
      </motion.div>
    </motion.div>
  );
}
