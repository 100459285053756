'use client';

import { useRouter, useSearchParams } from 'next/navigation';

import { SortByClient } from './SortByClient';

export function ClientSortBy({ sortOrder, setSortOrder }: { sortOrder: string; setSortOrder: React.Dispatch<React.SetStateAction<string>> }) {
  const router = useRouter();
  const searchParams = useSearchParams();

  const handleSortOrder = (sortOrder: string): void => {
    setSortOrder(sortOrder);
    const params = new URLSearchParams(searchParams?.toString() ?? '');
    params.set('sO', sortOrder);
    router.push(`?${params.toString()}`, { scroll: false });
  };

  return (
    <>
      <article className="col-span-full row-start-1 flex gap-2 justify-self-end md:hidden">
        <SortByClient sortOrder={sortOrder} setSortOrder={handleSortOrder} />
      </article>
      <article className="col-span-full row-start-1 hidden justify-self-center text-WHITE md:block">
        <div className="sticky top-[calc(100%-8dvh)] z-40 -mb-30">
          <SortByClient sortOrder={sortOrder} setSortOrder={handleSortOrder} isMobile />
        </div>
      </article>
    </>
  );
}