'use client';

import clsx from 'clsx';
import Image from 'next/image';
import { useState } from 'react';

import { ContenfulProductCarouselItem } from '@/app/types/contentful/types';

import ProductCarouselItemMobile from './ProductCarouselItemMobile';

export default function CarouselMobile({ carouselTitle, carouselDescription, carouselItemsCollection }: any) {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  return (
    <article className="col-span-full mt-20 hidden w-full flex-col items-center justify-center justify-self-center sm:mt-0 sm:flex">
      <div className="w-full max-w-[800px] text-center sm:text-left">
        <h2 className="pb-4 text-Body font-semibold tracking-normal text-GREEN-500">{carouselTitle}</h2>
        <p className="text-balance text-[1.5rem] font-light tracking-normal text-WHITE sm:text-Body-Large">{carouselDescription}</p>
      </div>

      {/* Carousel Items Mobile */}
      <div className="mt-10 flex w-full snap-x snap-mandatory gap-10px overflow-y-hidden overflow-x-scroll">
        {carouselItemsCollection.items.map((item: ContenfulProductCarouselItem, index: number) => (
          <ProductCarouselItemMobile key={item._id} item={item} index={index} setActiveIndex={setActiveIndex} />
        ))}
      </div>

      {/* Carousel Buttons Mobile */}
      <div className="mt-5 flex w-full flex-wrap justify-between md:hidden">
        {carouselItemsCollection.items.map((item: ContenfulProductCarouselItem, index: number) => (
          <button
            key={item.buttonTitle}
            className={clsx('justify-space-between flex w-fit min-w-fit items-center rounded-lg border px-[26px] py-[16px]', {
              'border-[rgba(138,190,255,_0.4)] bg-BLUE-950': index === 0 && activeIndex === 0,
              'border-[rgba(95,_236,_150,_0.4)] bg-GREEN-950': index === 1 && activeIndex === 1,
              'border-[rgba(241,_156,_255,_0.4)] bg-PURP-950': index === 2 && activeIndex === 2,
              'border-[rgba(131,_202,_255,_0.4)] bg-BLUE-950': index === 3 && activeIndex === 3,
              'border-WHITE/40': index !== activeIndex,
            })}
          >
            {item.buttonIcon?.url && (
              <Image
                src={item.buttonIcon.url}
                alt={item.buttonIcon.description || item.buttonIcon.title}
                width={item.buttonIcon.width}
                height={item.buttonIcon.height}
                sizes="(max-width: 25px) 100vw, 25px"
                draggable={false}
                className="h-[28px] w-[28px] object-contain"
              />
            )}
          </button>
        ))}
      </div>
    </article>
  );
}
