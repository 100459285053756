import React from 'react';

import { SelectBox } from './SelectBox';

interface SortByProps {
  selectedCategory: string;
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
}

export function SortBy({ selectedCategory, setSelectedCategory }: SortByProps) {
  return (
    <>
      {/* Desktop */}
      <article className="col-span-full row-start-1 flex gap-2 justify-self-end md:hidden">
        <SelectBox selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
      </article>
      {/* Mobile */}
      <article className="col-span-full row-start-1 hidden justify-self-center text-WHITE md:block">
        <div className="sticky top-[calc(100%-8dvh)] z-40 -mb-15">
          <SelectBox selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} isMobile />
        </div>
      </article>
    </>
  );
}
  