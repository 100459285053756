'use client';

import { AnimatePresence, motion } from 'motion/react';
import { useSearchParams } from 'next/navigation';
import { useMemo,useState } from 'react';

import AnimatedButton from './AnimatedButton';
import { ClientSortBy } from './ClientSortBy';
import ListingCard from './ListingCard';
import type { ListingCardProps } from './ListingComponent';

interface ClientListingComponentProps {
  cardsCollection: {
    items: ListingCardProps[];
  };
  numberOfCards: number;
}

export default function ClientListingComponent({ cardsCollection, numberOfCards }: ClientListingComponentProps) {
  const [showAll, setShowAll] = useState<boolean>(false);
  const searchParams = useSearchParams() ?? new URLSearchParams();
  const [sortOrder, setSortOrder] = useState<string>(searchParams.get('sO') || 'default');

  const numberOfCardsToShow = numberOfCards || 0;
  const cardsCollectionArrayLength = cardsCollection?.items?.length || 0;

  const handleShowToggle = (): void => {
    setShowAll(prev => !prev);
    if (showAll) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const sortedCards = useMemo(() => {
    if (!cardsCollection?.items) return [];

    const cards = [...cardsCollection.items];

    switch (sortOrder) {
      case 'furthest':
        return cards.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      case 'closest':
        return cards.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
      default:
        return cards;
    }
  }, [cardsCollection?.items, sortOrder]);

  return (
    <div className="container grid h-full grid-cols-12 gap-10 sm:gap-0">
      <ClientSortBy sortOrder={sortOrder} setSortOrder={setSortOrder} />

      <AnimatePresence mode="wait">
        <motion.article layout className="col-span-full grid grid-cols-12 gap-x-8 gap-y-20 md:row-start-1 sm:gap-x-0 sm:gap-y-15">
          {(showAll ? sortedCards : sortedCards.slice(0, numberOfCardsToShow)).map((card: ListingCardProps, index: number) => (
            <ListingCard key={`${card._id}-${index}`} card={card} />
          ))}
        </motion.article>
      </AnimatePresence>

      {cardsCollectionArrayLength > numberOfCardsToShow && (
        <motion.article
          layout="position"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="col-span-full flex justify-center pt-30 md:pt-80 sm:pt-60"
        >
          <AnimatedButton
            showAll={showAll}
            onClick={handleShowToggle}
            className="border-2 border-[#6100FF] bg-DEEPPURPLE/10 px-40px py-10px text-[1rem] text-[#6100FF] hover:bg-PURP/10"
          />
        </motion.article>
      )}
    </div>
  );
}