export default function sanitizeMarkdown(text: string): string {
    if (!text) return '';
    
    return text
      // Remove headers (##, ###, etc)
      .replace(/^#+\s*/gm, '')
      // Remove bold/italic markers (* or **)
      .replace(/\*+([^*]+)\*+/g, '$1')
      // Remove underscores for italic/bold
      .replace(/_+([^_]+)_+/g, '$1')
      // Remove links but keep text [text](url) -> text
      .replace(/\[([^\]]+)\]\([^)]+\)/g, '$1')
      // Remove any remaining square brackets
      .replace(/\[|\]/g, '')
      // Remove any remaining parentheses that were part of links
      .replace(/\(\s*https?:\/\/[^)]+\)/g, '')
      // Trim whitespace and normalize spaces
      .trim()
      .replace(/\s+/g, ' ');
  }