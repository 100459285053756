"use client"

import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import ClientCTAButton from '../Buttons/ClientCTAButton';
import RichTextComponent from '../RichText/RichTextComponent';
import { AccordionItem } from './Accordion';

export default function FaqComponent({ content }: any) {
  const {
    faqComponent: { label, title, description, ctaButton, accordionItemsCollection },
  } = content;

  // Track which accordion item is currently open
  const [openItem, setOpenItem] = React.useState<string | null>("item-0");

  // Handler for toggling accordion items
  const handleToggle = (value: string) => {
    setOpenItem(prevOpenItem => prevOpenItem === value ? null : value);
  };

  return (
    <section className="container grid w-full grid-cols-12 pb-52 pt-20 text-WHITE">
      <article className="col-span-3 row-start-1 md-lg:col-span-6 md:col-span-full">
        {label && (
          <p className="mb-3 text-[1rem] font-semibold text-GREEN-500">
            {label}
          </p>
        )}
        {title && (
          <h1 className="mt-2 text-H2 text-WHITE sm:text-H3">
            {title}
          </h1>
        )}
        {description && <ReactMarkdown remarkPlugins={[remarkGfm]}>{description || ''}</ReactMarkdown>}
        {ctaButton && (
          <ClientCTAButton
            buttonData={ctaButton}
            className="mt-8 inline-block border-green-600 font-semibold sm:text-xs"
          />
        )}
      </article>

      <div 
        role="complementary"
        aria-labelledby="faq-heading"
        className="col-start-5 col-end-12 row-start-1 md-lg:col-span-full md-lg:row-start-2 md-lg:mt-10"
      >
        <h2 id="faq-heading" className="sr-only">
          Frequently Asked Questions
        </h2>
          {accordionItemsCollection?.items?.map((item: any, index: number) => (
            <AccordionItem
              key={index}
              value={`item-${index}`}
              isOpen={openItem === `item-${index}`}
              onToggle={handleToggle}
              trigger={
                <span className="text-start text-Body-Large font-bold">
                  {item.question}
                </span>
              }
            >
              {item.richTextAnswer && (
                <RichTextComponent
                  content={{ richTextComponent: { text: item.richTextAnswer } }}
                  locationData="faq"
                />
              )}
              {item.ctaButton && (
                <ClientCTAButton
                  buttonData={item.ctaButton}
                  className="mb-3 mt-2 inline-block"
                />
              )}
            </AccordionItem>
          ))}
      </div>
    </section>
  );
}
