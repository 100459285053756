'use client';

import { AnimatePresence, motion, Variants } from 'motion/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { ListingTile } from '@/app/components/ui/NewsAndPress/ListingTile';
import { cardLabels,fadeInUp } from '@/app/components/ui/NewsAndPress/sharedData';
import { SortBy } from '@/app/components/ui/NewsAndPress/SortBy';
import type { ContentfulListingTileProps } from '@/app/types/contentful/types';

export const categories = ['News', 'Press Release', 'Report', 'Article', 'Interview', 'Podcast'] as const;

const stagger = {
  animate: {
    transition: {
      staggerChildren: 0.1,
    },
  },
} as const;

interface NewsPressListingsComponentClientProps {
  cards: ContentfulListingTileProps[];
}

export default function NewsPressListingsComponentClient({ cards }: NewsPressListingsComponentClientProps) {
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [displayedItemsCount, setDisplayedItemsCount] = useState(10);
  const [key, setKey] = useState(0);

  const filteredListings = useMemo(() => {
    return cards.filter((item: ContentfulListingTileProps) => {
      const label = item.label;
      if (!selectedCategory) return true;
      if (selectedCategory === 'Press Release') {
        return label === 'Press' || label === 'Press Release';
      }
      return cardLabels[label as keyof typeof cardLabels] === selectedCategory;
    });
  }, [cards, selectedCategory]);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [selectedCategory]);

  useEffect(() => {
    if (inView && displayedItemsCount < filteredListings.length) {
      setDisplayedItemsCount(prev => Math.min(prev + 10, filteredListings.length));
    }
  }, [inView, filteredListings.length, displayedItemsCount]);

  return (
    <>
      <SortBy selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />

      <AnimatePresence mode="wait">
        {filteredListings.length > 0 ? (
          <motion.section
            key={key}
            className="z-0 col-span-full grid auto-rows-[minmax(225px,_1fr)] grid-cols-subgrid gap-x-10 gap-y-10 justify-self-center md:row-start-1 sm:auto-rows-[minmax(200px,_1fr)]"
            variants={stagger}
            initial="initial"
            animate="animate"
          >
            {filteredListings.slice(0, displayedItemsCount).map((item, index) => (
              <ListingTile key={`${item._id}-${index}`} card={item} />
            ))}
          </motion.section>
        ) : (
          <motion.div variants={fadeInUp as Variants} className="col-span-full text-center text-Body-Large font-bold text-WHITE">
            None Found 😢
          </motion.div>
        )}
      </AnimatePresence>

      {displayedItemsCount < filteredListings.length && (
        <div ref={ref} className="col-span-full h-10" />
      )}
    </>
  );
}