'use client';

import { AnimatePresence, motion } from 'motion/react';
import Image from 'next/image';
import { useEffect, useState } from 'react';

import { paddingClasses } from '@/app/lib/utils/getPaddingClasses';
import shuffleArray from '@/app/lib/utils/shuffleArray';
import type { ContentfulImage } from '@/app/types/contentful/types';

import { SvgLinesComponent } from './SvgLinesComponent';

export default function LogoComponent({ content }: any) {
  const {
    logoComponent: { title, logosCollection, showGridLines, gradientType, spacingTop, spacingBottom },
  } = content;

  const paddingTop = paddingClasses.top?.[spacingTop as keyof typeof paddingClasses.top] || 'pt-4';
  const paddingBottom = paddingClasses.bottom?.[spacingBottom as keyof typeof paddingClasses.bottom] || 'pb-4';

  // Create a state to track the visible logos and queued logos
  const [logos, setLogos] = useState<ContentfulImage[]>([]); // All available logos from Contentful
  const [visibleLogos, setVisibleLogos] = useState<ContentfulImage[]>([]); // Currently displayed logos (max 8)
  const [isVisible, setIsVisible] = useState<boolean[]>(Array(12).fill(true)); // Visibility state for each position
  const [animating, setAnimating] = useState(false); // Flag to prevent overlapping animations

  // Initialize logos on component mount
  useEffect(() => {
    if (logosCollection?.items?.length) {
      const initialLogos = [...logosCollection.items];
      setLogos(initialLogos);
      setVisibleLogos(initialLogos.slice(0, 12)); // Show first 8 logos initially
    }
  }, [logosCollection]);

  // Set up the staggered animation cycle
  useEffect(() => {
    // Skip if no logos available or already in the middle of an animation
    if (!logos.length || animating) return;

    /**
     * Logo Animation Cycle
     *
     * This animation works by:
     * 1. Generating a new set of logos where each position gets a different logo
     * 2. Creating a random sequence of indices to determine animation order
     * 3. For each position, staggering the animations according to the random sequence
     * 4. First fading out the current logo
     * 5. Then replacing it with a new logo from the unique array
     * 6. Finally fading in the new logo
     */

    // Start a new animation cycle after delay
    const animationTimeout = setTimeout(() => {
      // Set flag to prevent new animation cycles until this one completes
      setAnimating(true);

      // Create a uniquely shuffled array where no logo appears in the same position
      const newLogos = getUniqueShuffledLogos(visibleLogos.slice(0, 8), [...logos]);

      // Generate a random animation sequence (which positions animate in which order)
      const randomSequence = getRandomAnimationSequence();

      // Track positions that have completed animating
      const completedPositions = new Set<number>();

      // Process each logo position in a random order
      const sequenceLength = randomSequence.length;
      for (let i = 0; i < sequenceLength; i++) {
        const position = randomSequence[i];
        const sequenceIndex = i;
        // STEP 1: Fade out the current logo at this position
        // We use setTimeout with the sequenceIndex to create the staggered effect
        setTimeout(() => {
          // Set the visibility state for this position to false (triggers fade out)
          setIsVisible(prev => {
            const newVisibility = [...prev];
            newVisibility[position] = false;
            return newVisibility;
          });

          // STEP 2: Replace the logo after it has faded out
          setTimeout(() => {
            // Update the logo at this position with the new one from the uniquely shuffled array
            setVisibleLogos(prev => {
              const updatedLogos = [...prev];
              updatedLogos[position] = newLogos[position];
              return updatedLogos;
            });

            // STEP 3: Fade in the new logo
            setIsVisible(prev => {
              const newVisibility = [...prev];
              newVisibility[position] = true;
              return newVisibility;
            });

            // Add this position to the completed set
            completedPositions.add(position);

            // STEP 4: When all positions have been updated, prepare for the next animation cycle
            if (completedPositions.size === 8) {
              // Wait for the final fade-in to complete before allowing a new cycle
              setTimeout(() => setAnimating(false), 1000);
            }
          }, 1500); // Wait 1000ms for the fade-out animation to complete
        }, sequenceIndex * 1000); // Stagger based on the sequence index, not the position value
      }
    }, 2500); // Wait 2.5 seconds before starting the animation cycle

    // Clean up function to cancel the animation if component unmounts
    return () => clearTimeout(animationTimeout);
  }, [logos, animating, visibleLogos]); // Re-run this effect if logos or animating state changes

  // Handle the case when there aren't enough logos
  const displayedLogos = visibleLogos.length >= 8 ? visibleLogos.slice(0, 8) : [...visibleLogos];
  while (displayedLogos.length < 8) {
    displayedLogos.push(visibleLogos[0] || ({} as ContentfulImage));
  }

  return (
    <section className={`relative grid h-full w-full grid-cols-12 ${paddingTop} ${paddingBottom}`}>
      {/* SVG lines container */}
      {showGridLines && <SvgLinesComponent />}

      <section className="container z-10 col-span-full grid max-w-[1140px] grid-cols-8 text-WHITE min-2xl:max-w-[1440px]">
        {title && <h2 className="col-span-full mb-13 justify-self-center text-center text-[2rem]">{title}</h2>}
        <article className="col-span-full grid grid-cols-subgrid gap-x-5 gap-y-15 justify-self-center md:h-auto md:gap-2">
          {displayedLogos.map((logo, index) => (
            <motion.div
              key={`${logo.title || 'logo'}-${index}`}
              className="col-span-2 flex h-[80px] items-center justify-center self-center justify-self-center md:col-span-4"
              layout
              style={{ willChange: "transform" }}
            >
              <AnimatePresence mode="wait">
                {logo.url && isVisible[index] && (
                  <motion.div
                    key={`${logo.url}-${index}`}
                    initial={{ opacity: 0, scale: 0.95, filter: 'blur(10px)' }}
                    animate={{ opacity: 1, scale: 1, filter: 'blur(0px)' }}
                    exit={{ opacity: 0, scale: 0.95, filter: 'blur(10px)' }}
                    transition={{ 
                      duration: 1.5,
                      type: "tween",
                      ease: [0.66, 0, 0.34, 1]
                    }}
                    className="flex h-[80px] w-[170px] items-center justify-center"
                    style={{ willChange: "transform, opacity, filter" }}
                  >
                    <Image
                      alt={logo.description || logo.title || 'Partner logo'}
                      width={logo.width || 150}
                      height={logo.height || 80}
                      src={logo.url}
                      draggable={false}
                      className="h-auto max-h-[80px] w-auto max-w-[170px] object-contain"
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </article>
      </section>

      {gradientType === 'Purple Gradient' ? (
        <div className="pointer-events-none absolute -z-10 h-full w-full select-none bg-gradient-bottom-purple bg-cover bg-bottom bg-no-repeat" />
      ) : gradientType === 'Blue Gradient' ? (
        <div className="pointer-events-none absolute -z-10 h-full w-full select-none bg-gradient-bottom-blue bg-cover bg-bottom bg-no-repeat" />
      ) : null}
    </section>
  );
}

/**
 * Ensures each position gets a different logo than currently displayed
 * @param currentLogos - Array of currently displayed logos
 * @param allLogos - Array of all available logos
 * @returns Array of new logos with no position having the same logo as before
 */
const getUniqueShuffledLogos = (currentLogos: ContentfulImage[], allLogos: ContentfulImage[]): ContentfulImage[] => {
  // If we don't have enough logos for a truly unique shuffle,
  // fall back to regular shuffling
  if (allLogos.length <= currentLogos.length) {
    return shuffleArray([...allLogos]);
  }

  // Create a new array to hold our result
  const result: ContentfulImage[] = new Array(currentLogos.length);

  // For each position, find a logo that's different from the current one
  for (let i = 0; i < currentLogos.length; i++) {
    // Create an array of eligible logos for this position
    // (all logos except the one currently in this position)
    const currentLogoUrl = currentLogos[i]?.url;
    const eligibleLogos = allLogos.filter(logo => logo.url !== currentLogoUrl);

    if (eligibleLogos.length === 0) {
      // Fallback if we somehow have no eligible logos
      result[i] = allLogos[Math.floor(Math.random() * allLogos.length)];
    } else {
      // Pick a random logo from eligible ones
      const randomIndex = Math.floor(Math.random() * eligibleLogos.length);
      result[i] = eligibleLogos[randomIndex];

      // Remove the selected logo from the pool to ensure we don't reuse it
      // (Only if we have enough logos left)
      if (allLogos.length > currentLogos.length - i) {
        const selectedLogoIndex = allLogos.findIndex(logo => logo.url === eligibleLogos[randomIndex].url);
        if (selectedLogoIndex !== -1) {
          allLogos.splice(selectedLogoIndex, 1);
        }
      }
    }
  }

  return result;
};

/**
 * Generates a randomized sequence of indices from 0 to 7
 * This creates a random order for animating the logos
 * @returns Array of indices from 0-7 in random order
 */
const getRandomAnimationSequence = (): number[] => {
  // Create an array of indices from 0-7
  const indices = Array.from({ length: 8 }, (_, i) => i);
  // Shuffle the indices to get a random sequence
  return shuffleArray([...indices]);
};
