import clsx from 'clsx';
import { motion, Variants } from 'motion/react';
import Image from 'next/image';
import { Link } from 'next-view-transitions';
import { useState } from 'react';
import { InViewHookResponse, useInView } from 'react-intersection-observer';

import LoadingSpinner from '@/app/components/ui/Loader/LoadingSpinner';
import { getHref } from '@/app/lib/utils/getHref';
import sanitizeMarkdown from '@/app/lib/utils/sanitizeMarkdown';
import { BlogListingCardProps } from '@/app/types/contentful/types';

// Animation variants for fading in and moving up
const fadeInUp: Variants = {
  initial: { opacity: 0, y: 20 }, // Start invisible and slightly below final position
  animate: { opacity: 1, y: 0 }, // Animate to fully visible and final position
  exit: { opacity: 0, y: -20 }, // Exit by fading out and moving slightly up
} as const;

export default function BlogListingCard({ card, initiallyVisible }: { card: BlogListingCardProps; initiallyVisible: boolean }) {
  const { title, coverImage, route, slug, tags, seoDescription } = card;

  const [loadedImages, setLoadedImages] = useState<{ [key: string]: boolean }>({});

  const href: string = getHref(route, slug, null);
  const [ref, inView]: InViewHookResponse = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  const [showAllTags, setShowAllTags] = useState(false);

  const handleImageLoad = (id: string) => {
    setLoadedImages(prev => ({
      ...prev,
      [id]: true,
    }));
  };

  const cardVariants = initiallyVisible
    ? {
        initial: { opacity: 1, y: 0 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -20 },
      }
    : fadeInUp;

  return (
    <motion.div
      ref={ref}
      layout
      variants={cardVariants}
      initial="initial"
      animate={inView || initiallyVisible ? 'animate' : 'initial'}
      exit="exit"
      transition={{ duration: 0.3, ease: 'easeInOut', delay: initiallyVisible ? 0 : 0.1 }}
      className="col-span-4 md:col-span-6 sm:col-span-full"
    >
      <motion.div layout className="group flex flex-col gap-y-3 text-WHITE">
        <Link href={href || '#'} className="contents">
          <div className="relative h-full w-full">
            {coverImage ? (
              <>
                {!loadedImages[card.sys.id] && (
                  <div className="absolute inset-0 flex animate-pulse items-center justify-center rounded-md border border-WHITE/20 bg-OFF-BLACK/50">
                    <LoadingSpinner className="h-15 w-15" />
                  </div>
                )}
                <Image
                  src={coverImage.url}
                  alt={coverImage.description || coverImage.title || ''}
                  width={coverImage.width || 500}
                  height={coverImage.height || 500}
                  sizes="(max-width: 768px) 100vw, 768px"
                  onLoad={() => handleImageLoad(card.sys.id)}
                  draggable={false}
                  className={clsx(
                    'aspect-video rounded-md border border-WHITE/20 object-cover transition-all duration-500',
                    loadedImages[card.sys.id] ? 'opacity-100' : 'opacity-0',
                  )}
                />
              </>
            ) : (
              <Image
                src="/assets/placeholder.svg"
                alt="placeholder"
                width={500}
                height={500}
                sizes="(max-width: 768px) 100vw, 768px"
                draggable={false}
                className="aspect-video h-full w-full rounded-md bg-green-900 object-cover"
              />
            )}
          </div>
          <h2 className="relative line-clamp-2 whitespace-pre-wrap text-H4">
            <span className="inline bg-gradient-to-r from-WHITE to-WHITE bg-[size:0%_0px] bg-[position:0_100%] bg-no-repeat leading-tight transition-all duration-500 ease-underline-bezier group-hover:bg-[size:100%_1px]">
              {title}
            </span>
          </h2>
          <p className="line-clamp-2 text-Body text-WHITE/60">{sanitizeMarkdown(seoDescription as string)}</p>
        </Link>
      </motion.div>
      <motion.div layout="size" className="mt-8 flex flex-wrap gap-x-2 gap-y-1 text-WHITE">
        {tags.slice(0, showAllTags ? tags.length : 4).map((tag: string, index: number) => {
          const slugifiedTag = tag.toLowerCase().split(' ').join('-');
          return (
            <motion.span
              layout="position"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key={index}
              className="whitespace-nowrap rounded-md border border-WHITE/50 px-2 py-1 text-Body text-[0.85rem] transition-colors duration-200 hover:bg-WHITE/5 hover:border-GREEN-500"
            >
              <Link href={`/blog/tag/${slugifiedTag}`} title={`View all blogs tagged with "${tag}"`}>
                {tag}
              </Link>
            </motion.span>
          );
        })}
        {tags.length > 4 && (
          <motion.button
            layout="position"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              setShowAllTags(!showAllTags);
            }}
            className="whitespace-nowrap rounded-md border border-WHITE/50 px-2 py-1 text-Body text-[0.85rem] transition-colors duration-200 hover:bg-WHITE/5 hover:border-GREEN-500"
          >
            {showAllTags ? 'X' : '...'}
          </motion.button>
        )}
      </motion.div>
    </motion.div>
  );
}
