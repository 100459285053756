'use client';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types';
import { BrightStar, Link, WarningTriangleSolid } from 'iconoir-react';
import { motion, useInView } from 'motion/react';
import { useRef } from 'react';

interface ComparisonCardProps {
  cardType: string;
  cardTextContent: {
    json: Document;
  };
}

export default function ComparisonCard({ cardType, cardTextContent }: ComparisonCardProps) {
  const isOurProduct = cardType === 'Our Product';
  const cardRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(cardRef, { once: true, margin: '0px 0px -100px 0px' });

  const getOptionsForCardType = (cardType: string) => ({
    renderNode: {
      [BLOCKS.HEADING_1]: (_node: any, children: any) => (
        <h1 className="pb-2 text-[1.75rem] font-bold text-WHITE/90 sm:text-[1.5rem]">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (_node: any, children: any) => (
        <h2 className="pb-2 text-[1.5rem] font-bold text-WHITE/90 sm:text-[1.25rem]">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (_node: any, children: any) => (
        <h3 className="pb-2 text-[1.25rem] font-bold text-WHITE/90 sm:text-[1.125rem]">{children}</h3>
      ),
      [BLOCKS.PARAGRAPH]: (_node: any, children: any) => <p className="text-balance text-Body font-normal text-WHITE">{children}</p>,
      [BLOCKS.QUOTE]: (_node: any, children: any) => (
        <blockquote className="text-balance rounded-md bg-slate-700 p-4 pt-3 text-Body-Large font-normal text-WHITE">{children}</blockquote>
      ),
      [BLOCKS.HR]: (_node: any, _children: any) => <hr className="border-t-2 border-WHITE/50 pb-5 pt-8" />,
      [BLOCKS.UL_LIST]: (_node: any, children: any) => (
        <ul className={`pl-6 text-WHITE ${cardType === 'Our Product' ? 'list-["✓"]' : 'list-["✕"]'}`}>{children}</ul>
      ),
      [BLOCKS.LIST_ITEM]: (_node: any, children: any) => (
        <li className={`mb-2 pl-2 text-Body ${cardType === 'Our Product' ? 'marker:text-GREEN-500' : 'marker:text-red-500'}`}>
          {children}
        </li>
      ),
      [INLINES.HYPERLINK]: (node: any, children: any) => {
        const href = node.data.uri;
        return (
          <Link href={href} target="_blank" className="text-BLUE-400 underline">
            {children}
          </Link>
        );
      },
    },
  });

  return (
    <motion.article
      ref={cardRef}
      className={`relative rounded-lg ${isOurProduct ? 'bg-GREEN/35 ring-1 ring-inset ring-green-400 backdrop-blur-sm' : 'bg-red-500/15 ring-1 ring-inset ring-red-500 backdrop-blur-sm'} p-4`}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 10 }}
      transition={{ duration: 0.5, ease: 'easeOut' }}
    >
      <div className="absolute right-3 top-3 md:-left-3 md:-top-3">
        {isOurProduct ? (
          <span className="inline-block rounded-lg bg-GREEN-950 p-1.5 shadow-[0_0_5px_2px_rgba(74,222,128,0.5)]">
            <BrightStar className="h-5 w-5 text-GREEN-500" />
          </span>
        ) : (
          <span className="inline-block rounded-lg bg-BLACK p-1.5 shadow-[0_0_5px_2px_rgba(255,0,0,0.4)]">
            <WarningTriangleSolid className="mb-px h-5 w-5 text-red-500" />
          </span>
        )}
      </div>
      <div>{documentToReactComponents(cardTextContent.json as Document, getOptionsForCardType(cardType))}</div>
    </motion.article>
  );
}
