'use client';

import clsx from 'clsx';

export function SortByClient({ sortOrder, setSortOrder, isMobile }: { sortOrder: string; setSortOrder: (sortOrder: string) => void, isMobile?: boolean }) {
  return (
    <SelectBox sortOrder={sortOrder} setSortOrder={setSortOrder} isMobile={isMobile} />
  );
}

function SelectBox({
  sortOrder,
  setSortOrder,
  isMobile,
}: {
  sortOrder: string;
  setSortOrder: (sortOrder: string) => void;
  isMobile?: boolean;
}) {
  return (
    <div className="relative">
      <select
        aria-label="Sort by"
        value={sortOrder}
        onChange={e => setSortOrder(e.target.value)}
        className={clsx(
          'relative cursor-pointer appearance-none rounded-md border border-[#6100FF] bg-[#0C195A] py-2 pl-4 pr-10 text-[#6100FF] transition-colors duration-300 hover:bg-BLUE-950/80',
          { 'w-[calc(100vw-12vw)]': isMobile, 'w-28': !isMobile },
        )}
      >
        <option value="default">Default</option>
        <option value="furthest">Furthest</option>
        <option value="closest">Closest</option>
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-[#6100FF]">
        <svg className="mr-2 h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  );
}