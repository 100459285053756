import clsx from 'clsx';

import { getHref } from '@/app/lib/utils/getHref';
import { paddingClasses } from '@/app/lib/utils/getPaddingClasses';
import { ContentfulCard } from '@/app/types/contentful/types';

import LinkComponent from '../Interactive/Link';
import { SvgLinesComponent } from '../SvgLinesComponent';

type CardProps = {
  card: ContentfulCard;
  index: number;
};

const CardsComponent = ({ content }: { content?: any }) => {
  const { cardsComponent: { cardsCollection, showGridLines, spacingTop, spacingBottom } } = content;

  const paddingTop = paddingClasses.top?.[spacingTop as keyof typeof paddingClasses.top] || 'pt-4';
  const paddingBottom = paddingClasses.bottom?.[spacingBottom as keyof typeof paddingClasses.bottom] || 'pb-4';

  return (
    <section className="relative">
      {showGridLines && <SvgLinesComponent />}
      <div className={`container grid grid-cols-12 gap-5 ${paddingTop} ${paddingBottom}`}>
        {cardsCollection?.items &&
          cardsCollection.items.map((card: ContentfulCard, index: number) => <Card key={`${card._id}-${index}`} card={card} index={index} />)}
      </div>
    </section>
  );
};

export default CardsComponent;

const labelColors: { [key: string]: string } = {
  'label-1': 'text-GREEN-500',
  'label-2': 'text-PURP-200',
  'label-3': 'text-BLUE-400',
} as const;

const borderColors: { [key: string]: string } = {
  'label-1': 'hover:border-GREEN-500 active:border-GREEN-500',
  'label-2': 'hover:border-PURP-200 active:border-PURP-200',
  'label-3': 'hover:border-BLUE-400 active:border-BLUE-400',
} as const;

function Card({ card, index }: CardProps) {
  const href: string = getHref(card.route, card.slug, card.url);


  const labelColor = labelColors[`label-${(index % 3) + 1}`] || 'text-WHITE';
  const borderColor = borderColors[`label-${(index % 3) + 1}`] || 'hover:border-WHITE/80';

  return (
    <LinkComponent
      className={clsx(
        'col-span-4 md:col-span-12 grid grid-cols-6 gap-4 rounded-md border-[1px] border-WHITE/20 bg-OFF-BLACK p-5 sm:p-10px',
        'transition-all duration-300 ease-linear hover:bg-WHITE/5',
        borderColor
      )}
      target='_blank'
      href={href}
    >
      <div className="col-span-5">
        <p className={clsx('text-xs font-bold', labelColor)}>{card.label}</p>
        <p className="mt-2 text-[24px] text-WHITE leading-[110%] min-h-[2em]">{card.title}</p>
      </div>
      <span className="col-start-6 row-start-1 self-start justify-self-end rounded-md border-none bg-WHITE/20 px-10px py-10px text-WHITE hover:bg-opacity-60 sm:px-[6px] sm:py-[6px]">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 12 12" fill="none">
          <path
            d="M1 11L10.9999 1M10.9999 1V10.6M10.9999 1H1.4"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
      <p className="col-span-full self-end text-Body text-WHITE/60">{card.description}</p>
    </LinkComponent>
  );
}
