'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import RSAHappyHourFormInputs from '@/app/components/ui/Forms/RSA25_HappyHourForm/RSAHappyHourInputs';
import { getHubSpotCookie } from '@/app/lib/utils/getHubSpotCookie';

// Form Schema: Defines the structure and validation rules for the form data
const formSchema = z.object({
  firstname: z.string().min(2, 'First name is required'),
  lastname: z.string().min(2, 'Last name is required'),
  email: z.string().email('Invalid email address'),
  mobilephone: z.string().regex(/^\+?[1-9]\d{1,14}$/, 'Invalid phone number'),
  company: z.string().min(1, 'Company name is required'),
  // Add optional UTM fields
  utm_source: z.string().optional(),
  utm_medium: z.string().optional(),
  utm_campaign: z.string().optional(),
  utm_content: z.string().optional(),
  utm_term: z.string().optional(),
});

// Form Data Type: Inferred from the schema for type safety
type FormData = z.infer<typeof formSchema>;

export default function RSAHappyHourForm({ handleCloseOnSubmit }: { handleCloseOnSubmit?: () => void }): JSX.Element {
  const [submittedMessage, setSubmittedMessage] = useState<string | null>(null);
  const [serverError, setServerError] = useState<string | null>(null);
  const [formContent, setFormContent] = useState<any>(null);

  // Initialize react-hook-form with zod resolver for validation
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
  });

  // Extract UTM parameters from URL on component mount
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const utmParams = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_content',
        'utm_term'
      ];
      
      utmParams.forEach(param => {
        const value = urlParams.get(param);
        if (value) {
          setValue(param as keyof FormData, value);
        }
      });
    }
  }, [setValue]);

  // Fetch form content from API on component mount
  useEffect(() => {
    fetch(`/api/getForm?formType=RSA 2025 Happy Hour`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(content => setFormContent(content))
      .catch(error => console.error('Error fetching form content:', error));
  }, []);

  // Form submission handler
  const onSubmit: SubmitHandler<FormData> = async data => {
    try {
      // Transform the data before sending to HubSpot
      const formattedFields = [
        { name: 'firstname', value: data.firstname },
        { name: 'lastname', value: data.lastname },
        { name: 'email', value: data.email },
        { name: 'mobilephone', value: data.mobilephone },
        { name: 'company', value: data.company },
        // Add UTM parameters if they exist
        ...(data.utm_source ? [{ name: 'utm_source', value: data.utm_source }] : []),
        ...(data.utm_medium ? [{ name: 'utm_medium', value: data.utm_medium }] : []),
        ...(data.utm_campaign ? [{ name: 'utm_campaign', value: data.utm_campaign }] : []),
        ...(data.utm_content ? [{ name: 'utm_content', value: data.utm_content }] : []),
        ...(data.utm_term ? [{ name: 'utm_term', value: data.utm_term }] : []),
      ];

      // Get IP address and hubspot cookie
      const [ipResponse, hutk] = await Promise.all([
        fetch('https://api.ipify.org?format=json').then(r => r.json()),
        getHubSpotCookie() // This now loads the script temporarily, gets the cookie, and removes the script
      ]);

      // Send form data to HubSpot Forms API
      const response = await fetch(formContent?.submitUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: formattedFields,
          context: {
            pageUri: 'https://www.cyware.com/rsa-2025-happy-hour',
            pageName: 'RSA 2025 Happy Hour',
            hutk,
            ipAddress: ipResponse.ip,
          },
        }),
      });

      if (!response.ok) {
        throw new Error('Form submission failed');
      }

      setSubmittedMessage('Thank you for registering!');

      // Reset form and clear submission message after a delay
      setTimeout(() => {
        reset();
        handleCloseOnSubmit && handleCloseOnSubmit();
        setSubmittedMessage(null);
      }, 1500);
    } catch (error) {
      console.error('Form submission error:', error);
      setServerError('An error occurred. Please try again.');
    }
  };

  // Error handler for form validation errors
  const onError: SubmitErrorHandler<FormData> = errors => {
    console.error('Form validation errors:', errors);
  };

  return (
    <>
      <RSAHappyHourFormInputs
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        onError={onError}
        errors={errors}
        register={register}
        isSubmitting={isSubmitting}
        submittedMessage={submittedMessage}
        serverError={serverError}
      />
    </>
  );
}

// ? This form submits via HubSpot Forms API and Hubspot Tracking Cookie (without the tracking script)
